<template>
    <b-container id="content">
        <p style="text-align: center">The table below shows the surveys you have taken. You can also earn more TON by taking the surveys below.</p>

        <div class="custom-title mt-30">SURVEYS</div>
        <div id="surveys-history">
            <b-table :items="items" :fields="fields" bordered show-empty :responsive="true" :dark="true"
                     :class="{ 'table-loading': table_loading }" style="font-size: 12px">
                <template v-slot:empty>
                    <div class="text-center">No more results</div>
                </template>
                <template v-slot:cell(createdAt)="data">{{ timeStampToString(data.item.createdAt*1000) }}</template>
                <template v-slot:cell(site)="data">{{ data.item.site.toUpperCase() }}</template>
                <template v-slot:cell(ton_amount)="data">
                    <div style="display: flex; gap: 5px; justify-content: center;">
                        <div class="flex-middle"><img :src="`${CDN_URL}/images/coin/TON.webp`"
                                                      width="16"
                                                      height="16"/></div>
                        <div class="flex-middle">
                            {{ (data.item.ton_amount / 1e8).toFixed(5) }}
                        </div>
                    </div>
                </template>
                <template v-slot:cell(status)="data">
              <span
                      :class="(data.item.status === 0  ? 'text-warning' : (data.item.status === 1) ? 'text-success' : 'text-danger')">
                {{
                  SURVEY_STATUS[data.item.status]
                  }}
              </span>
                </template>

            </b-table>
        </div>
        <div class="text-center">
            <el-pagination layout="prev, pager, next" @current-change="changePage" :page-size="10"
                           :current-page="currentPage" :total="total"></el-pagination>
        </div>
    </b-container>
</template>
<script>
import config, {NUMBER_OF_DECIMAL_PLACES} from "@/config.js";
import Service from "../../services/Service";

export default {
    name: "Surveys",
    async created() {
        this.loading = true;
        await this.getSurveys();
        this.loading = false;
    },
    data() {
        return {
            NUMBER_OF_DECIMAL_PLACES: NUMBER_OF_DECIMAL_PLACES,
            SURVEY_STATUS: [
                'Pending',
                'Success',
                'Canceled'
            ],
            table_loading: true,
            loading: true,
            items: [],
            total: 0,
            currentPage: 1,
            fields: [
                {
                    key: "createdAt",
                    label: "Date",
                    class: "text-center"
                },
                {
                    key: "site",
                    label: "Site",
                    class: "text-center"
                },
                {
                    key: "ton_amount",
                    label: "Earning",
                    class: "text-center"
                },
                {
                    key: "status",
                    label: "Status",
                    class: "text-center"
                },
            ],
        };
    },
    methods:{
        async getSurveys() {
            this.table_loading = true;
            const res = await Service.getSurveyHistory({
                page: this.currentPage
            }).catch(() => {
            });
            this.table_loading = false;
            if (res !== undefined && res.data.success === true) {
                this.items = res.data.list;
                this.total = res.data.total;
            }
        },
        timeStampToString(unix_timestamp) {
            const date = new Date(unix_timestamp);
            return (
                ("0" + date.getDate()).slice(-2) +
                "/" +
                ("0" + (date.getMonth() + 1)).slice(-2) +
                "/" +
                date.getFullYear() +
                " " +
                ("0" + date.getHours()).slice(-2) +
                ":" +
                ("0" + date.getMinutes()).slice(-2)
            );
        },
        changePage(page) {
            this.currentPage = page;
            this.getSurveys();
        },

    },
    computed: {
        CDN_URL() {
            return config.CDN_URL;
        },
    }
};
</script>

<style >
#surveys-history table.b-table {
    background: transparent;
}

#surveys-history .table-bordered th,
#surveys-history .table-bordered td {
    border: none;
    border-bottom: 1px solid #2f3f4b;
}

.table-loading {
    opacity: 0.2;
    transition: all 0.5s;
}
</style>