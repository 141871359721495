<template>
    <div>
        <b-navbar toggleable="lg" type="dark" variant="info" fixed="top">
            <b-container>
                <button type="button" v-on:click="sidebar_enable = true" class="navbar-toggler">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <b-navbar-brand :to="{ name: 'Faucet' }">
                    <img :src="`/images/logo.png`" alt="BitKong.io" title="BitKong.io" id="logo"/>
                    <img :src="`${CDN_URL}/images/blackgame.png`" alt="BitKong.io" title="BitKong.io"
                         id="logo-mobile"/>
                </b-navbar-brand>
                <div id="balance-mobile" v-if="isLogged">
                    <div class="mobile-balance-token" v-on:click="showChooseTokenModal">
                        <div class="flex-middle"><img :src="`/images/coin/${selectedToken}.webp`" class="coin-icon"/>
                        </div>
                        <div class="flex-middle">
                            {{ (animated_balance / 1e8).toFixed(NUMBER_OF_DECIMAL_PLACES[selectedToken]) }}
                        </div>
                        <div class="flex-middle">
                            <svg class="arrow-down icon">
                                <use xlink:href="/images/symbol-defs.svg?lang.svg#icon_Arrow"></use>
                            </svg>
                        </div>
                    </div>
                    <b-button variant="success" v-on:click="deposit_mobile()" class="mobile-deposit-button">DEPOSIT
                    </b-button>

                    <div class="choose-token-background" v-on:click="closeChooseTokenModal"
                         v-if="isShowChooseTokenModal"></div>
                    <div class="choose-token-modal" v-if="isShowChooseTokenModal">
                        <div class="list-token">
                            <div v-for="token in tokenList" :key="token" class="token-item"
                                 :class="{ 'selected': selectedToken == token }" v-on:click="chooseToken(token)">
                                <div class="token-icon"><img :src="`/images/coin/${token}.webp`"/></div>
                                <div> {{ token }}</div>
                                <div class="token-item-balance">
                                    {{ (fullBalance[token] / 1e8).toFixed(NUMBER_OF_DECIMAL_PLACES[token]) }}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="notification-mobile" v-on:click="showNotify()">
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 32 32" role="presentation">
              <g>
                <path fill="#607488"
                      d="M27.2 20.1c-1.2-1.8-1.9-2.4-1.9-5.6V9.8c0-4.9-4-8.9-8.9-8.9s-8.9 4-8.9 8.9v4.5c0 3.2-.7 4.1-1.9 5.9-.8 1.2-2.3 3.2-3.2 4.4-.5.6-.1 1 .5 1h26.9c.6 0 .9-.4.5-1-.8-1.2-2.3-3.3-3.1-4.5z"/>
                <path fill="#7486b7" d="M16.5 31c1.9 0 3.5-1.6 3.5-3.5h-7c0 1.9 1.5 3.5 3.5 3.5z"/>

                <circle v-if="!readNotification" cx="24" cy="6" r="4" fill="red"/>
              </g>
            </svg>
          </span>
                </div>
                <b-collapse id="nav-collapse" is-nav>
                    <b-navbar-nav class="mr-auto" v-if="isLogged">
                        <b-nav-item-dropdown id="game-dropdown" text="Play" left>
                            <b-dropdown-item v-on:click="goToKong">
                                <img :src="`${CDN_URL}/images/kingkong.png`"/> KingKong
                            </b-dropdown-item>
                        </b-nav-item-dropdown>

                    </b-navbar-nav>

                    <b-navbar-nav class="ml-auto" v-if="isLogged">
                        <div class="balance">
                            <div class="flex-middle">
                <span class="balance-token flex" v-on:click="showChooseTokenModal">
                  <div class="flex-middle">
                    <img :src="`/images/coin/${selectedToken}.webp`" class="coin-icon"/>
                  </div>
                  <div class="flex-middle">
                    {{ (animated_balance / 1e8).toFixed(NUMBER_OF_DECIMAL_PLACES[selectedToken]) }}
                  </div>
                  <div class="flex-middle">
                    <svg class="arrow-down icon">
                      <use xlink:href="/images/symbol-defs.svg?lang.svg#icon_Arrow"></use>
                    </svg>
                  </div>
                </span>
                            </div>
                            <div class="flex-middle">
                                <b-button variant="success" v-on:click="deposit()" class="deposit-button">
                                    DEPOSIT
                                </b-button>
                            </div>
                            <div class="choose-token-background" v-on:click="closeChooseTokenModal"
                                 v-if="isShowChooseTokenModal"></div>
                            <div class="choose-token-modal" v-if="isShowChooseTokenModal">
                                <div class="list-token">

                                    <div v-for="token in tokenList" :key="token" class="token-item"
                                         :class="{ 'selected': selectedToken == token }"
                                         v-on:click="chooseToken(token)">
                                        <div class="token-icon"><img :src="`/images/coin/${token}.webp`"/></div>
                                        <div> {{ token }}</div>
                                        <div class="token-item-balance">
                                            {{ (fullBalance[token] / 1e8).toFixed(NUMBER_OF_DECIMAL_PLACES[token]) }}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <b-nav-item class="notification-nav-item" v-on:click="showNotify()">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 32 32" role="presentation">
                  <g>
                    <path fill="#607488"
                          d="M27.2 20.1c-1.2-1.8-1.9-2.4-1.9-5.6V9.8c0-4.9-4-8.9-8.9-8.9s-8.9 4-8.9 8.9v4.5c0 3.2-.7 4.1-1.9 5.9-.8 1.2-2.3 3.2-3.2 4.4-.5.6-.1 1 .5 1h26.9c.6 0 .9-.4.5-1-.8-1.2-2.3-3.3-3.1-4.5z"/>
                    <path fill="#7486b7" d="M16.5 31c1.9 0 3.5-1.6 3.5-3.5h-7c0 1.9 1.5 3.5 3.5 3.5z"/>

                    <circle v-if="!readNotification" cx="24" cy="6" r="4" fill="red"/>
                  </g>
                </svg>
              </span>
                        </b-nav-item>
                        <b-nav-item-dropdown class="profile-dropdown" :text="email.slice(0, 10)" right>
                            <b-dropdown-item :to="{ name: 'Faucet' }">Faucet</b-dropdown-item>
                            <b-dropdown-item :to="{ name: 'Deposit' }">Deposit</b-dropdown-item>
                            <b-dropdown-item :to="{ name: 'Withdraw' }">Withdraw</b-dropdown-item>
                            <b-dropdown-item :to="{ name: 'Affiliate' }">Affiliate</b-dropdown-item>
                            <b-dropdown-item v-if="false" :to="{ name: 'Rankings' }">Rankings</b-dropdown-item>
                            <b-dropdown-item :to="{ name: 'Surveys' }">Surveys</b-dropdown-item>
                            <b-dropdown-item :to="{ name: 'Contact' }">Contact</b-dropdown-item>
                            <b-dropdown-item :to="{ name: 'Profile' }">Profile</b-dropdown-item>
                            <b-dropdown-item to="#" v-on:click="logout">Logout</b-dropdown-item>
                        </b-nav-item-dropdown>
                    </b-navbar-nav>
                </b-collapse>
            </b-container>
        </b-navbar>
        <div class="sub-header">
            <div class="sub-header-wrap">
                <div id="broadcast">
                    <svg class="hron-img" version="1.1" xmlns="http://www.w3.org/2000/svg"
                         xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000"
                         enable-background="new 0 0 1000 1000" xml:space="preserve">
            <g>
              <g>
                <g>
                  <g>
                    <path
                            d="M569,137.9c-11.6,3.1-20.9,12.6-28.2,26.9c-42,50.8-223.7,255.6-473.7,322.6L29,497.6C7.9,503.2,7,567.9,13.8,593.4c6.8,25.4,40,81,61.1,75.3l23.4-6.2l168.8,200.7l96.3-25.8l-83.6-133.1c6.8-4.9,13-11.3,6.7-20.2c-5.7-8.1-19.9-15.9-31.1-18.7l-17.7-28.3C444,619.1,633,681.9,684.5,701.1c13.4,8.7,26.2,12.3,37.8,9.2c52.7-14.2,61-153.9,18.8-311.8C698.8,240.6,621.7,123.8,569,137.9z M715.5,684.7c-24.7,6.6-96.4-80.2-139.8-241.9c-43.3-161.7-24.5-272.8,0-279.4c24.6-6.6,96.4,80.2,139.7,241.9C759,567,740.2,678.1,715.5,684.7z"/>
                      <path
                              d="M630.4,366.8c-21,5.6-20.9,33.1-12.5,64.7c8.5,31.6,22.1,55.4,43.2,49.8s31.3-35.9,22.8-67.5C675.5,382.3,651.4,361.1,630.4,366.8z"/>
                  </g>
                    <g>
                    <g>
                      <path
                              d="M950.9,554.9l-25.6-14.8c34.8-60.4,44.1-130.7,26.1-198c-18-67.3-61.3-123.5-121.6-158.4l14.8-25.7C911.9,197,960,259.6,980,334.5C1000,409.4,989.7,487.7,950.9,554.9z"/>
                    </g>
                        <g>
                      <path
                              d="M879.3,513.5l-25.7-14.8c23.9-41.2,30.2-89.2,17.8-135.2c-12.3-46-41.8-84.4-83-108.2l14.8-25.6c48.1,27.7,82.5,72.5,96.8,126.1C914.5,409.5,907.1,465.5,879.3,513.5z"/>
                    </g>
                        <g>
                      <path
                              d="M807.6,472.2l-25.6-14.8c12.7-22.1,16.1-47.8,9.5-72.4c-6.6-24.7-22.4-45.2-44.5-58l14.8-25.6c28.9,16.7,49.7,43.6,58.3,76C828.8,409.6,824.4,443.3,807.6,472.2z"/>
                    </g>
                  </g>
                </g>
              </g>
                <g/>
                <g/>
                <g/>
                <g/>
                <g/>
                <g/>
                <g/>
                <g/>
                <g/>
                <g/>
                <g/>
                <g/>
                <g/>
                <g/>
                <g/>
            </g>
          </svg>
                    <div class="msg">
                        <div class="text" :class="{ 'blur-message': (blurMessage) }">
                            <b v-html="randomMessage"></b>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="sidebar" :class="{ 'active': sidebar_enable }" v-on:click="sidebar_enable = false">
            <div class="xscrollview sidebar-wrap">
                <div class="logo">
                    <img :src="`/images/logo.png`"/>
                </div>

                <div class="nav">
                    <div class="game-list">GAMES</div>
                    <div class="game-item game-kingkong coming-soon" v-on:click="goToKong()">
                        <img :src="`${CDN_URL}/images/kingkong.png`" alt class="game-img"/>
                        <div class="game-name">KINGKONG</div>
                    </div>

                    <!--<div class="mining-item" v-on:click="goToCloudMining()">
                      <img :src="`${CDN_URL}/images/cloud-mining/cloud.png`" alt class="game-img" />
                      <div class="game-name">{{countDownText===''?'CLOUD MINING':countDownText}}</div>
                    </div>-->

                    <div class="menu-link">
                        <div class="menu-link-item">
                            <b-link :to="{ name: 'Faucet' }">Faucet</b-link>
                        </div>
                        <div class="menu-link-item">
                            <b-link :to="{ name: 'Deposit' }">Deposit</b-link>
                        </div>

                        <div class="menu-link-item">
                            <b-link :to="{ name: 'Withdraw' }">Withdraw</b-link>
                        </div>

                        <div class="menu-link-item">
                            <b-link :to="{ name: 'Affiliate' }">Affiliate</b-link>
                        </div>

                        <div class="menu-link-item">
                            <b-link :to="{ name: 'Surveys' }">Surveys</b-link>
                        </div>
                        <div class="menu-link-item">
                            <b-link :to="{ name: 'Profile' }">Profile</b-link>
                        </div>
                        <div class="menu-link-item">
                            <b-link :to="{ name: 'Contact' }">Contact</b-link>
                        </div>

                        <div class="menu-link-item">
                            <b-link to="#" v-on:click="logout">Logout</b-link>
                        </div>
                    </div>
                </div>

                <svg v-on:click="sidebar_enable = false" xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                     viewBox="0 0 32 32" role="presentation" class="xicon sidebar-close xicon-IconClose">
                    <path
                            d="M28.473 24.977l-9.072-9.072 8.882-8.882-3.307-3.307-8.882 8.882-8.977-8.977-3.307 3.307 8.882 8.977-9.166 9.166 3.402 3.307 9.166-9.166 9.072 9.166z"/>
                </svg>
            </div>
        </div>

        <div class="install-alert" :class="{ 'active': showInstallAlert }">
            <div class="container">
                <div class="close-button" v-on:click="hideInstallNotify">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"
                         role="presentation"
                         class="xicon sidebar-close xicon-IconClose">
                        <path
                                d="M28.473 24.977l-9.072-9.072 8.882-8.882-3.307-3.307-8.882 8.882-8.977-8.977-3.307 3.307 8.882 8.977-9.166 9.166 3.402 3.307 9.166-9.166 9.072 9.166z"/>
                    </svg>
                </div>
                <span>
          <button type="button" class="btn btn-primary install-button" v-on:click="installPWA">Install now</button>
          <span class="ml-3">You can now install Blackgame like an app within seconds. Just click the install
            button.</span>
        </span>
            </div>
        </div>
    </div>
</template>

<script>
import Service from "../services/Service";
import {TweenLite} from "gsap";
import Cookie from "vue-cookie";

const timeout = (ms) => new Promise((res) => setTimeout(res, ms));
import config from "../config";
import {NUMBER_OF_DECIMAL_PLACES} from "../config";

export default {
    name: "Header",
    created() {
        TweenLite.to(this.$data, 0.5, {animated_balance: this.balance});
        this.installRefused = Cookie.get("install_pwa") !== null;
        window.addEventListener("beforeinstallprompt", (e) => {
            e.preventDefault();
            this.deferredPrompt = e;
            if (!this.installRefused) {
                setTimeout(() => {
                    this.showInstallAlert = true;
                }, 2000);
            }
        });

        setInterval(async () => {
            this.blurMessage = true;
            await timeout(500);
            this.randomMessage = this.broadcastMessage[
                Math.round(Math.random() * (this.broadcastMessage.length - 1))
                ];
            this.blurMessage = false;
        }, 5000);

        // if (this.timeRemaining > 0) {
        //   this.countDownItv = setInterval(() => {
        //     this.timeRemaining = 1591574400000 - new Date().getTime();
        //     if (this.timeRemaining <= 0) {
        //       clearInterval(this.countDownItv);
        //     }
        //   }, 1000);
        // }
    },
    data() {
        return {
            showInstallAlert: false,
            sidebar_enable: false,
            animated_balance: 0,
            installRefused: false,
            deferredPrompt: null,
            randomMessage:
                "Welcome to BitKong.io - Start Free Bitcoin Mining Without any Investment...",
            blurMessage: false,
            // cloudMiningTime: 1591574400000,
            // timeRemaining: 1591574400000 - new Date().getTime(),
            countDownItv: null,
            isShowChooseTokenModal: false,
            tokenList: ["TON"],
            NUMBER_OF_DECIMAL_PLACES: NUMBER_OF_DECIMAL_PLACES
        };
    },
    methods: {
        installPWA() {
            this.showInstallAlert = false;
            this.deferredPrompt.prompt();
            this.deferredPrompt.userChoice.then(() => {
                this.deferredPrompt = null;
            });
        },
        hideInstallNotify() {
            this.showInstallAlert = false;
            Cookie.set("install_pwa", "false");
        },
        async logout() {
            const request = await Service.logout().catch(() => {
            });
            if (request !== undefined && request.data.success) {
                await this.$store.dispatch("setLogged", false);
                await this.$store.dispatch("setBalance", {
                    BTC: '0',
                    BNB: '0',
                    USDT: '0',
                    TON: '0'
                });
                await this.$store.dispatch("setUserID", 0);
                window.location.href = "https://blackgame.io";
                this.$toastr.s("Logout success");
            }
        },
        async deposit_mobile() {
            if (this.$router.currentRoute.name !== "Deposit") {
                await this.$router.replace({name: "Deposit"});
            }
        },
        async deposit() {
            await this.$store.dispatch("setIsShowNotify", false);
            // await this.$store.dispatch("setIsShowDeposit", true);
            await this.$router.replace({name: "Deposit"});
        },
        async goToMultiply() {
            if (this.$router.currentRoute.name !== "MultiplyBTC") {
                await this.$router.replace({name: "MultiplyBTC"});
            }
        },
        async goToCrash() {
            if (this.$router.currentRoute.name !== "Crash") {
                await this.$router.replace({name: "Crash"});
            }
        },
        async goToKong() {
            if (this.$router.currentRoute.name !== "Kong") {
                await this.$router.replace({name: "Kong"});
            }
        },
        async showNotify() {
            await this.$store.dispatch("setIsShowNotify", true);
            await this.$store.dispatch("setReadNotification", true);
            await this.$store.dispatch("setIsShowDeposit", false);
        },
        closeChooseTokenModal() {
            this.isShowChooseTokenModal = false;
        },
        showChooseTokenModal() {
            this.isShowChooseTokenModal = true;
        },
        async chooseToken(token) {
            this.isShowChooseTokenModal = false;
            await this.$store.dispatch("setSelectedToken", token);
        }
    },
    computed: {
        isLogged() {
             return this.$store.getters.isLogged;
        },
        balance() {
            return this.$store.getters.balance[this.selectedToken];
        },
        fullBalance() {
            return this.$store.getters.balance;
        },
        broadcastMessage() {
            return this.$store.getters.broadcastMessage;
        },
        email() {
            return this.$store.getters.email;
        },
        readNotification() {
            return this.$store.getters.readNotification;
        },
        // countDownText() {
        //   if (this.timeRemaining <= 0) {
        //     return "";
        //   }
        //   const days = Math.floor(this.timeRemaining / (1000 * 60 * 60 * 24));
        //   const hours = Math.floor(
        //     (this.timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        //   );
        //   const minutes = Math.floor(
        //     (this.timeRemaining % (1000 * 60 * 60)) / (1000 * 60)
        //   );
        //   const seconds = Math.floor((this.timeRemaining % (1000 * 60)) / 1000);
        //   return ` (${days}d ${hours}h ${minutes}m ${seconds}s)`;
        // },
        CDN_URL() {
            return config.CDN_URL;
        },
        selectedToken() {
            return this.$store.getters.selectedToken;
        },
    },
    watch: {
        balance: function (newValue) {
            TweenLite.to(this.$data, 0.5, {animated_balance: newValue});
        },
    },
    beforeDestroy() {
        clearInterval(this.countDownItv);
    },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");

body,
body code,
body kbd,
body pre,
body samp {
    font-family: Open Sans, sans-serif;
    color: #ffffff;
}

.blur-message {
    opacity: 0.1;
}

li.nav-item {
    padding: 0 5px;
}

li.nav-item a {
    text-transform: uppercase;
    color: #eef0f6 !important;
    line-height: 40px;
    position: relative;
    font-family: Roboto;
    white-space: nowrap;
    font-weight: bold;
}

li.nav-item a::after {
    position: absolute;
    bottom: 7px;
    left: 0;
    content: "";
    width: 0;
    height: 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    margin-left: 50%;
    background-size: 200% auto;
    background-image: linear-gradient(264deg, #f7da42, #ffbc34, #f7da42);
}

.nav-link.is-active::after {
    width: 80%;
    height: 4px;
    margin-left: 10%;
}

.nav-link.is-active {
    color: #ffe22b !important;
}

.balance {
    position: relative;
    display: flex;
    gap: 5px;
    padding: 0.5rem 1rem;
}

.balance .coin-icon {
    width: 20px;
    height: 20px;
}

.balance .flex {
    display: flex;
}

.balance .flex-middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.balance .balance-token {
    color: #ede9e1;
    font-weight: 600;
    padding: 0 5px;
    border: 2px solid #2d3e4c;
    border-radius: 3px;
    font-size: 14px;
    cursor: pointer;
    gap: 5px;
    padding-right: 10px;
    height: 31px;
}

.bg-info {
    background: #1c2932 !important;
    border-bottom: 1px solid #273642;
}

.navbar-brand img#logo {
    height: 30px;
    width: auto;
}

.navbar-brand img#logo-mobile {
    display: none;
}

.notification-nav-item a {
    padding-top: 9px;
}

.notification-nav-item span {
    border: 2px solid #2d3e4c;
    padding: 4px;
    border-radius: 3px;
}

.notification-mobile {
    margin-top: 1px;
}

.notification-mobile span {
    border: 2px solid #2d3e4c;
    padding: 4px;
    border-radius: 3px;
}

.profile-dropdown a span {
    text-transform: lowercase;
    font-size: 14px;
}

.profile-dropdown li a.dropdown-item {
    text-transform: none;
}

.profile-dropdown .dropdown-menu .dropdown-item {
    font-size: 14px;
}

#balance-mobile {
    text-align: center;
    display: none;
    position: relative;
}

#balance-mobile .mobile-balance-token {
    color: #ede9e1;
    font-weight: 600;
    padding: 5px;
    border: 2px solid #2d3e4c;
    border-radius: 3px;
    font-size: 14px;
    display: flex;
    gap: 3px;
    height: 30px;
}

#balance-mobile .mobile-balance-token .coin-icon {
    width: 16px;
    height: 16px;
}

#balance-mobile .mobile-balance-token button {
    margin-left: 3px;
    padding: 3px;
    font-size: 10px;
    line-height: 1.5;
    border-radius: 0.2rem;
    margin-bottom: 2px;
}

#balance-mobile .flex-middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.sub-header {
    height: 3rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: fixed;
    top: 57px;
    width: 100%;
    background-color: #273642;
}

.sub-header-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: auto;
    flex: auto;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    max-width: 1140px;
    margin: 0 auto;
    z-index: 9999;
}

.sub-header #broadcast {
    -webkit-box-flex: 1;
    -ms-flex: auto;
    flex: auto;
    height: 3rem;
    line-height: 3rem;
    z-index: 9999;
}

#broadcast {
    position: relative;
    text-align: left;
    height: 2.25rem;
    line-height: 2.3125rem;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding-left: 3.4375rem;
    font-size: 0.75rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

#broadcast .hron-img {
    width: 30px;
    height: 30px;
    position: absolute;
    left: 10px;
    top: 50%;
    margin-top: -15px;
    fill: #f7b01f;
}

#broadcast .msg {
    position: absolute;
    top: 0;
    right: 0;
    left: 3.4375rem;
    height: 100%;
}

#broadcast .msg .text {
    white-space: nowrap;
    overflow: hidden;
    color: #fff;
    text-overflow: ellipsis;
    display: inline-block;
    width: 100%;
    cursor: pointer;
    font-size: 0.8125rem;
    transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    -webkit-transition: opacity 0.5s ease-in-out;
}

.dropdown-menu {
    color: #6e8899;
    background-color: #394d5f;
    margin: 0 !important;
}

.dropdown-menu .dropdown-item {
    color: #6e8899 !important;
    font-size: 15px;
    line-height: 30px;
}

.dropdown-item:hover,
.dropdown-item:focus {
    color: #88a5b8 !important;
    text-decoration: none;
    background: none;
}

.install-alert {
    background: #273642;
    top: -500px;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    z-index: 1000;
    transition: all 0.5s ease;
    position: absolute;
}

.install-alert .container {
    position: relative;
    padding: 10px 20px;
}

.install-alert.active {
    top: 57px;
}

.install-alert .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    color: #ffffff;
    cursor: pointer;
}

.install-alert .close-button svg {
    width: 14px;
    fill: #ffffff;
}


.choose-token-modal {
    position: absolute;
    width: 250px;
    padding: 10px;
    top: 55px;
    right: 0.5rem;
    background: #22303b;
    border-radius: 5px;
    font-size: 14px;
}

.choose-token-modal .list-token {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.choose-token-modal .list-token .token-item {
    display: flex;
    gap: 10px;
    padding: 5px 10px;
    line-height: 30px;
    cursor: pointer;
    border-radius: 3px;
}

.choose-token-modal .list-token .token-item.selected {
    border: 1px solid #28a745;
    background: transparent !important;
    cursor: inherit;
}

.choose-token-modal .list-token .token-item:hover {
    background: rgba(158, 158, 159, 0.8)
}

.choose-token-modal .list-token .token-item .token-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.choose-token-modal .list-token .token-item .token-icon img {
    width: 20px;
    height: 20px;
}

.choose-token-modal .list-token .token-item .token-item-balance {
    flex-grow: 1;
    text-align: right;
}

#game-dropdown li a.dropdown-item img {
    width: 30px;
    margin-right: 10px;
}

.deposit-button {
    line-height: 16px;
    font-size: 14px;
    height: 31px;
    margin-left: -10px;
}

.choose-token-background {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.3)
}

svg.arrow-down {
    fill: #abb6c2;
    width: .75rem;
    height: .75rem;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}

.game-item-link {
    font-size: 14px;
    display: flex;
    gap: 5px;
    text-transform: none;
}

.game-item-link img {
    width: 20px;
    height: 20px;
}

.mobile-balance-token {
    cursor: pointer;
}

.mobile-deposit-button {
    height: 30px;
    font-size: 10px;
    margin-left: -5px;
}

#balance-mobile .choose-token-modal {
    position: absolute;
    width: 250px;
    padding: 10px;
    top: 45px;
    right: 0;
    background: #22303b;
    border-radius: 5px;
    font-size: 14px;
}

@media only screen and (min-width: 992px) {
    nav.navbar {
        padding: 0;
    }

    .notification-mobile {
        display: none;
    }

    #sidebar {
        display: none;
    }
}

@media only screen and (max-width: 991px) {
    #sidebar {
        position: fixed;
        left: -1500px;
        right: 1500px;
        display: block;
        color: #fff;
        background-size: 100%;
        top: 0;
        bottom: 0;
        z-index: 10000;
        -webkit-transition: all 1s cubic-bezier(0.36, 0.66, 0.04, 1);
        transition: all 1s cubic-bezier(0.36, 0.66, 0.04, 1);
    }

    #sidebar.active {
        right: 0;
        left: 0;
    }

    #sidebar .sidebar-wrap {
        left: 0;
        width: 350px;
        height: 100vh;
        position: absolute;
        background-color: #293946;
        text-align: center;
        overflow-y: auto;
    }

    #sidebar .sidebar-wrap .logo {
        padding: 40px 0 30px 0;
        background-color: #21313e;
    }

    #sidebar .sidebar-wrap .logo img {
        width: 250px;
        max-width: 80%;
    }

    #sidebar .sidebar-close {
        position: absolute;
        right: 0;
        top: 0;
        color: #2a3946;
        margin: 0.875rem 0.875rem 0 0;
        width: 2.125rem;
        height: 2.125rem;
        padding: 0.625rem;
        cursor: pointer;
    }

    #sidebar .nav {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        font-size: 0.875rem;
        line-height: 2.5rem;
        padding: 1.25rem 1.875rem;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        cursor: pointer;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }

    #sidebar .nav .game-list {
        width: 100%;
        margin-bottom: 0.625rem;
        position: relative;
        height: 25px;
        font-size: 12px;
        text-align: left;
    }

    #sidebar .nav .game-item {
        background-color: #21313e;
        border-radius: 0.25rem;
        position: relative;
        margin-right: 0;
        width: 100%;
        height: 70px;
        border: none;
        margin-top: 5px;
    }

    #sidebar .nav .game-item.coming-soon .game-name {
        font-size: 9px;
    }

    #sidebar .nav .game-crash .game-img {
        margin-top: 1.625rem;
    }

    #sidebar .nav .game-item .game-img {
        width: 2.5rem;
        margin-top: 0.75rem;
    }

    #sidebar .nav .game-item .game-name {
        position: absolute;
        bottom: -7px;
        left: 0;
        right: 0;
        text-align: center;
        font-size: 12px;
    }

    #sidebar .nav .mining-item {
        background-color: #21313e;
        border-radius: 0.25rem;
        position: relative;
        margin-right: 0;
        width: 100px;
        height: 75px;
        border: none;
        margin-top: 5px;
    }

    #sidebar .nav .mining-item .game-img {
        width: 3rem;
        margin-top: 0.75rem;
    }

    #sidebar .nav .mining-item .game-name {
        position: absolute;
        bottom: -7px;
        left: 0;
        right: 0;
        text-align: center;
        font-size: 12px;
    }

    #sidebar .nav .menu-link {
        width: 100%;
    }

    #sidebar .nav .menu-link-item:first-child {
        margin-top: 30px;
    }

    #sidebar .nav .menu-link-item a {
        margin-top: 10px;
        display: block;
        width: 100%;
        line-height: 2.125rem;
        background-image: none;
        border: 0.0625rem solid #3c4d5b;
        border-radius: 5px;
        color: #6e8899;
        text-decoration: none;
    }

    .xicon {
        display: inline-block;
        vertical-align: top;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        fill: #6e8899;
    }

    .navbar-toggler {
        border: 2px solid #2d3e4c !important;
        padding: 1px 4px !important;
    }

    .navbar-toggler-icon {
        width: 1em !important;
        height: 1em !important;
    }

    #balance-mobile {
        display: flex;
    }

    .balance {
        display: none;
    }

    .navbar-brand img#logo {
        display: none;
    }

    .navbar-brand img#logo-mobile {
        display: block;
        height: 30px;
        width: auto;
    }

    ul.navbar-nav {
        display: block;
    }

    li.nav-item {
        float: left;
        margin-right: 10px;
        width: 100%;
        text-align: right;
        border-top: 1px solid #465059;
        padding-right: 10px;
    }

    li.nav-item a.nav-link {
        display: inline;
        text-transform: none;
    }

    .nav-link.is-active::after {
        width: 80%;
        height: 2px;
        margin-left: 10%;
    }
}

@media only screen and (max-width: 621px) {
    #sidebar .sidebar-wrap {
        right: 23%;
        width: auto;
    }
}
</style>