import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import router from './router'
import store from './store/store'
import QRCodeVue from 'qrcode.vue'
import VueToast from 'vue-toastr'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueSweetAlert from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css'
import '@fortawesome/fontawesome-free/js/all.min.js'
import VueSocketIO from 'vue-socket.io'
import config from './config'
import VTooltip from 'v-tooltip'

Vue.config.productionTip = false;
Vue.use(VueToast);
Vue.use(VTooltip);
Vue.use(BootstrapVue);
Vue.use(new VueSocketIO({
    debug: false,
    connection: config.SOCKET_URL,
    vuex: {
        store,
        actionPrefix: 'SOCKET_'
    },
    options: {
        path: '/ws/'
    }
}));
Vue.use(VueRouter);
Vue.use(ElementUI);
Vue.use(VueSweetAlert);
Vue.prototype.$toastIns = null;

new Vue({
    router,
    store,
    render: h => h(App),
    mounted() {
        this.$toastr.defaultPosition = "toast-top-left";
    },
    components: {
        QRCodeVue: QRCodeVue,
    },
    sockets: {
        async disconnect() {
            await this.$store.dispatch('setCrashState', 'CONNECTION');
        }
    },
}).$mount('#app');