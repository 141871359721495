<template>
  <b-container id="content">
    <div class="text-center mt-5">
      <h2>404 Not Found</h2>
      <div>The page you are looking for was not found.</div>
      <div>
        <b-button class="go-to-home" v-on:click="$router.push({name: 'Home'})">Go to Homepage</b-button>
      </div>
    </div>
  </b-container>
</template>
<script>
export default {
  name: "Page404"
};
</script>
<style scoped>
.go-to-home {
  background: linear-gradient(264deg, #f7da42, #ffbc34, #f7da42);
  color: #fff;
  border: none;
  padding: 10px 50px;
  margin: 30px;
  border-radius: 5px;
  font-weight: bold;
  min-width: 180px;
}
</style>