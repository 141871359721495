<template>
    <div class="Frame79">
        <div class="Frame77">
            <div class="Frame74">
                <div class="Number">{{ getMinutesCountdown(seconds) }}</div>
            </div>
            <div class="Minutes"> Minutes</div>
        </div>
        <div class="Frame78">
            <div class="Frame75">
                <div class="Number">{{ getSecondsCountdown(seconds) }}</div>
            </div>
            <div class="Seconds"> Seconds</div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        seconds: Number,
    },
    name: "MiningCountdown",
    methods: {
        getMinutesCountdown(seconds) {
            if (seconds >= 60) {
                const minutes = Math.floor(seconds / 60);
                if (minutes > 9) return minutes;
                return "0" + minutes;
            }
            return "00";
        },
        getSecondsCountdown(seconds) {
            const _seconds = seconds % 60;
            if (_seconds > 9) return _seconds;
            return "0" + _seconds;
        },
    }
}
</script>

<style scoped>
.Frame79 {
    width: 172px;
    height: 116px;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    display: inline-flex
}

.Frame77 {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;
    display: inline-flex
}

.Frame74 {
    height: 92px;
    padding: 10px;
    background: rgba(255, 255, 255, 0.10);
    border: 0.50px white solid;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    display: flex;
    width: 80px;
}

.Minutes {
    color: rgba(255, 255, 255, 0.80);
    font-size: 12px;
    font-family: Urbanist;
    font-weight: 400;
    line-height: 18px;
    word-wrap: break-word
}

.Frame78 {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;
    display: inline-flex
}

.Frame75 {
    height: 92px;
    padding: 10px;
    background: rgba(255, 255, 255, 0.10);
    border: 0.50px white solid;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    display: flex;
    width: 80px;
}

.Seconds {
    color: rgba(255, 255, 255, 0.80);
    font-size: 12px;
    font-family: Urbanist;
    font-weight: 400;
    line-height: 18px;
    word-wrap: break-word
}

.Number {
    color: white;
    font-size: 48px;
    font-family: Urbanist;
    font-weight: 700;
    line-height: 72px;
    word-wrap: break-word
}
</style>