<template>
  <b-container id="content">
    <div style="margin: 0 auto;background: #22303b;padding: 20px;border-radius: 5px;">
      <h1>Provably Fair</h1>
      <div id="content_text">
        <h3>Dice</h3>
        <div>
          <p>
            When you open the game page the server generates a secret and a seed and reveals its hash (using
            HMAC
            SHA256 algorithm). The server secret represents a random number - initial roll (from 0 to 9999). The
            server
            seed is a cryptographically secure random alpha-numeric string. The hash of these 2 strings helps to
            ensure
            that the initial roll is not altered after you make a bet. After the game is finished the server
            secret
            and
            the server seed are revealed, so you can easily calculate and verify the hash.
          </p>
          <p>
            When you play a game you can pass an extra custom string - client seed (if it's not specified a
            random
            number is automatically generated by your browser). The server will then calculate another hash
            using
            the
            server secret, the server seed and the client seed. The last 5 chars of this hash (representing
            a
            hexadecimal value) will be converted to an integer. The result number will be used to adjust the
            initial
            roll. Because the client seed can not be predicted by the server the adjustment is completely
            random and
            hence you can be sure that the game result is fair.
          </p>
        </div>
      </div>
    </div>
  </b-container>
</template>
<script>
export default {
  name: "ProvablyFair"
};
</script>
<style scoped>
h1 {
  font-size: 22px;
}

#content_text {
  color: #6e8899;
  font-size: 14px;
}

#content_text h2 {
  font-size: 18px;
}

#content_text h3 {
  font-weight: 400;
  font-size: 1.0625rem;
  color: rgb(255, 255, 255);
  margin: 10px 0;
}
</style>