<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: "app",
    components: {},
     created() {
        window.Telegram.WebApp.ready();
    },
    beforeCreate() {
        window.Telegram.WebApp.expand();
    },

};
</script>

<style>
html,
body {
    margin: 0;
    height: 100vh;
    width: 100%;
    overflow-x: hidden;
}

body {
    overflow: hidden;
    background: #1c2933;
}

*:hover,
*:active,
*:focus {
    outline: none;
}

#app {
    width: 100%;
    height: 100%;
}

#content {
    padding-top: 20px;
    padding-bottom: 20px;
    min-height: calc(100vh - 200px);
}

.custom-title {
    text-align: center;
    background: #465059;
    padding: 10px;
    border-radius: 5px 5px 0 0;
    font-weight: 700;
    color: #ffffff;
    text-transform: uppercase;
}

.custom-body {
    background: #293946;
    color: #ffffff;
    border-radius: 0 0 5px 5px;
    border-width: 0 1px 1px 1px;
    border-style: solid;
    border-color: #293946;
    padding: 10px;
}

.swal2-popup {
    background: #22303b !important;
}

.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
    background: rgba(0, 0, 0, .75) !important;
}

.swal2-title,
.swal2-content {
    color: #ffffff !important;
}
</style>