<template>
  <footer>
    <div class="footer-main py-4 small">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-6 text-center text-lg-left">
            <span>COPYRIGHT © 2019 BITCOIN GAMBLING ALL RIGHT RESERVED</span>
          </div>
          <div class="col-12 col-lg-6 text-center text-lg-right">
            <router-link :to="{name: 'FAQ'}">FAQ</router-link>
            <span class="text-muted ml-2 mr-2">|</span>
            <router-link :to="{name: 'ProvablyFair'}">Provably fair</router-link>
            <span class="text-muted ml-2 mr-2">|</span>
            <router-link :to="{name: 'PrivacyPolicy'}">Privacy policy</router-link>
            <span class="text-muted ml-2 mr-2">|</span>
            <router-link :to="{name: 'TermsOfUse'}">Terms of use</router-link>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer"
};
</script>
<style>
footer {
  border-top: 1px solid #2f3d48;
  background: #1c2933;
  color: #657a8d;
  margin-top: 30px;
}

footer a {
  color: #657a8d;
}

footer a:hover {
  color: #ffe22b;
  text-decoration: none;
}
</style>