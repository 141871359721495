<template>
  <b-container id="content">
    <div
      style="margin: 0px auto;background: rgb(34, 48, 59) none repeat scroll 0% 0%;padding: 20px;border-radius: 5px;"
    >
      <div style="max-width: 600px; margin: 0 auto;">
        <div class="text-center title">Contact Us</div>
        <div class="text-center">If you have any questions or need help, contact us.</div>
        <div style="color: #6e8899;">
          <b-form-group
            id="input-group-address"
            label="Email address:"
            label-for="input-address"
            v-if="!isLogged"
          >
            <b-form-input id="input-address" v-model="form.email" type="text" autocomplete="off"></b-form-input>
          </b-form-group>
          <b-form-group id="input-group-subject" label="Subject:" label-for="input-address">
            <b-form-input id="input-subject" v-model="form.subject" type="text" autocomplete="off"></b-form-input>
          </b-form-group>
          <b-form-group id="input-group-message" label="Message:" label-for="input-address">
            <b-form-textarea style="height: 200px;" v-model="form.message"></b-form-textarea>
          </b-form-group>
          <b-button
            type="submit"
            variant="success"
            id="btn-change-password"
            style="width: 100%"
            v-on:click="send"
          >Send</b-button>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
import Service from "../../services/Service";
import Config from "../../config";

export default {
  name: "Contact",
  components: {
    // VueRecaptcha
  },
  created() {
    this.init();
  },
  data() {
    return {
      form: {},
      sitekey: Config.GOOGLE_RECAPTCHA_KEY
    };
  },
  methods: {
    onVerify: function(response) {
      this.form.reCaptchaResponse = response;
    },
    init() {
      this.form = {
        email: this.$store.getters.email,
        subject: "",
        message: ""
      };
    },
    async send() {
      const res = await Service.sendContact(this.form).catch(() => {});
      if (res !== undefined) {
        if (res.data.success) {
          this.$swal("Contact", res.data.message, "success").then(() => {
            this.init();
          });
        } else {
          this.$swal("Contact", res.data.message, "error");
        }
      }
    }
  },
  computed: {
    isLogged() {
      // return this.$store.getters.isLogged;
        return true;
    }
  }
};
</script>

<style scoped>
.title {
  text-transform: uppercase;
  color: rgb(255, 255, 255);
  font-weight: bold;
  padding: 0.75rem;
  font-size: 20px;
}
</style>