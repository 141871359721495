import EventBus from '../event-bus';
import Cookie from "vue-cookie";

export default {
    setLogged(state, isLogged) {
        state.isLogged = isLogged
    },
    setUserID(state, userID) {
        state.userID = userID
    },
    setTelegramID(state, telegramID){
        state.telegramID= telegramID
    },
    setEmail(state, email) {
        state.email = email
    },
    setAvatar(state, avatar) {
        state.avatar = avatar
    },
    setBalance(state, balance) {
        state.balance = balance
    },
    setAllBets(state, allBets) {
        state.allBets = allBets;
    },
    setCrash(state, crash) {
        state.crash = crash;
    },
    setCrashHistory(state, obj) {
        state.crash.history = obj;
    },
    setCrashMyBet(state, obj) {
        state.crash.myBets = obj;
    },
    setCrashAllBet(state, obj) {
        state.crash.allBets = obj;
    },
    setCrashState(state, crash_state) {
        state.crash.state = crash_state;
    },
    setIsShowDeposit(state, obj) {
        state.isShowDeposit = obj;
    },
    setIsShowNotify(state, obj) {
        state.isShowNotify = obj;
    },
    setReadNotification(state, obj) {
        state.readNotification = obj;
    },
    setSoundOpen(state, obj) {
        Cookie.set('soundOpen', obj);
        state.soundOpen = obj;
    },
    setSelectedToken(state, obj) {
        Cookie.set('selectedToken', obj);
        state.selectedToken = obj;
    },
    socketMultiply(state, data) {
        state.allBets.unshift(data);
        if (state.allBets.length > 20) {
            state.allBets.pop();
        }
    },
    socketKingKong(state, data) {
        EventBus.$emit('KINGKONG_BET', data);
    },
    socketMining(state, data) {
        EventBus.$emit('MINING', data);
    },
    socketCrash(state, obj) {
        if (obj.type === 'broadcast') {
            state.crash.state = obj.state;
            if (obj.state === 'PROGRESS') {
                state.crash.time = new Date().getTime() - obj.time;
                state.crash.joined = obj.joined;

                if (obj.joined_bet_amount !== undefined) {
                    state.crash.joined_bet_coin = obj.joined_bet_coin;
                    state.crash.joined_bet_amount = obj.joined_bet_amount;
                }
            }

            if (obj.state === 'STARTING') {
                state.crash.allBets.list = [];
                state.crash.time = new Date().getTime() + obj.time;
                state.crash.joined = obj.joined;

                if (obj.joined_bet_amount !== undefined) {
                    state.crash.joined_bet_amount = obj.joined_bet_amount;
                    state.crash.joined_bet_coin = obj.joined_bet_coin;
                }
            }

            if (obj.users !== undefined) {
                state.crash.allBets.list = obj.users;
                for (let i = 0; i < state.crash.allBets.list.length; i++) {
                    state.crash.allBets.list[i].user_id = parseInt(state.crash.allBets.list[i].user_id);
                }
                if (obj.state === 'PROGRESS' || obj.state === 'STARTING') {
                    for (let i = 0; i < state.crash.allBets.list.length; i++) {
                        state.crash.allBets.list[i].result = 0;
                    }
                }
            }

            if (obj.state === 'ENDED') {
                state.crash.time = obj.time;
                state.crash.history.list.unshift({
                    game_id: obj.game_id,
                    result: (Math.pow(Math.E, obj.time * 6e-5)).toFixed(2),
                    hash: obj.hash,
                    timestamp: new Date().getTime()
                });
                if (state.crash.history.list.length > 20) {
                    state.crash.history.list.pop();
                }
                const result = (Math.pow(Math.E, obj.time * 6e-5)).toFixed(2);
                for (let i = 0; i < state.crash.myBets.list.length; i++) {
                    if (state.crash.myBets.list[i].game_id === obj.game_id) {
                        state.crash.myBets.list[i].result = result;
                    }
                }
                for (let i = 0; i < state.crash.allBets.list.length; i++) {
                    if (state.crash.allBets.list[i].game_id === obj.game_id) {
                        state.crash.allBets.list[i].result = result;
                    }
                }
            }
        } else if (obj.type === 'bet_reply') {
            state.crash.joined = obj.joined;
            if (obj.joined_bet_amount !== undefined) {
                state.crash.joined_bet_amount = obj.joined_bet_amount;
                state.crash.joined_bet_coin = obj.joined_bet_coin;
            }
            if (obj.success) {
                EventBus.$emit('BET_SUCCESSFUL');
                state.balance = obj.balance;
            } else {
                EventBus.$emit('BET_ERROR', obj.message);
                if (obj.message === 'Insufficient balance, please top up!') {
                    EventBus.$emit('show_deposit');
                }
            }
        } else if (obj.type === 'cast_out_reply') {
            if (obj.success) {
                EventBus.$emit('CRASH_WON');
                state.crash.showEscape = true;
                state.crash.showEscapePhrase = 0;
                state.crash.cashOutAmount = (obj.cashOutAmount / 1e8).toFixed(8);

                setTimeout(() => {
                    state.crash.showEscapePhrase = 1;
                }, 200);

                setTimeout(() => {
                    state.crash.showEscapePhrase = 2;
                }, 700);

                setTimeout(() => {
                    state.crash.showEscapePhrase = 3;
                }, 1200);

                setTimeout(() => {
                    state.crash.showEscape = false;
                }, 1700);
            }
            state.crash.joined = obj.joined;
            state.balance = obj.balance;
        } else if (obj.type === 'bet') {
            state.crash.allBets.list.unshift({
                game_id: obj.round,
                user_id: obj.user_id,
                coin: obj.coin,
                result: 0,
                payout: 0,
                amount: obj.amount,
                timestamp: new Date().getTime()
            });
            if (obj.user_id === state.userID) {
                state.crash.myBets.list.unshift({
                    game_id: obj.round,
                    coin: obj.coin,
                    result: 0,
                    payout: 0,
                    amount: obj.amount,
                    timestamp: new Date().getTime()
                });
                if (state.crash.myBets.list.length > 20) {
                    state.crash.myBets.list.pop();
                }
            }
        } else if (obj.type === 'escape') {
            EventBus.$emit('USER_ESCAPE', { user_id: obj.user_id, payout: obj.payout });
            for (let i = 0; i < state.crash.allBets.list.length; i++) {
                if (state.crash.allBets.list[i].game_id === obj.round && state.crash.allBets.list[i].user_id === obj.user_id) {
                    state.crash.allBets.list[i].payout = obj.payout;
                    break;
                }
            }
            if (obj.user_id === state.userID) {
                for (let i = 0; i < state.crash.myBets.list.length; i++) {
                    if (state.crash.myBets.list[i].game_id === obj.round) {
                        state.crash.myBets.list[i].payout = obj.payout;
                        break;
                    }
                }
            }
        }
    },
    socketBCMsg(state, obj) {
        state.broadcastMessage.push(obj.message);
        if (state.broadcastMessage.length > 10) {
            state.broadcastMessage.shift();
        }
    },
    socketVideoPoker(state, data) {
        EventBus.$emit('VIDEO_POKER_BET', data);
    },
    socketKeno(state, data) {
        EventBus.$emit('KENO_BET', data);
    }
}