<template>
  <b-container id="content">
    <div style="margin: 0 auto;background: #22303b;padding: 20px;border-radius: 5px;">
      <h1>Terms of use</h1>
      <div id="content_text">
        <p>
          This end user agreement (the "Agreement") should be read by you (the "User" or "you") in its entirety
          prior to your use of Bitkong’s service or products. Please note that the Agreement constitutes a
          legally binding agreement between you and Bitkong (referred to herein as "Bitkong", "us" or "we") which
          owns and operates the Internet site found and games described at www.Bitkong.com (the "Service"). By
          clicking the "I Agree" button if and where provided and/or using the Service, you consent to the
          terms and conditions set forth in this Agreement.
        </p>
        <h3>1. Grant of License</h3>
        <ul>
          <li>
            1.1. Subject to the terms and conditions contained herein, Bitkong grants the User a
            non-exclusive, personal, non-transferable right to use the Service on your personal computer or
            other device that accesses the Internet in order to access the games available and described on
            the Bitkong.com website (the website and games together being the "Service").
          </li>
          <li>
            1.2. The Service is not for use by (i) individuals under 18 years of age, (ii) individuals under
            the legal age of majority in their jurisdiction and (iii) individuals accessing the Service from
            jurisdictions from which it is illegal to do so. Bitkong is not able to verify the legality of the
            Service in each jurisdiction and it is the User’s responsibility to ensure that their use of the
            Service is lawful.
          </li>
          <li>
            1.3. Bitkong and its licensors are the sole holders of all rights in and to the Service and code,
            structure and organization, including copyright, trade secrets, intellectual property and other
            rights. You may not, within the limits prescribed by applicable laws: (a) copy, distribute,
            publish, reverse engineer, decompile, disassemble, modify, or translate the website; or (b) use
            the Service in a manner prohibited by applicable laws or regulations (each of the above is an
            "Unauthorized Use"). Bitkong reserves any and all rights implied or otherwise, which are not
            expressly granted to the User hereunder and retain all rights, title and interest in and to the
            Service. You agree that you will be solely liable for any damage, costs or expenses arising out
            of or in connection with the commission by you of any Unauthorized Use. You shall notify Bitkong
            immediately upon becoming aware of the commission by any person of any Unauthorized Use and
            shall provide Bitkong with reasonable assistance with any investigations it conducts in light of
            the information provided by you in this respect.
          </li>
          <li>
            1.4. The term "Bitkong", its domain names and any other trade marks, or service marks used by Bitkong
            as part of the Service (the "Trade Marks"), are solely owned by Bitkong. In addition, all content
            on the website, including, but not limited to, the images, pictures, graphics, photographs,
            animations, videos, music, audio and text (the "Site Content") belongs to Bitkong and is protected
            by copyright and/or other intellectual property or other rights. You hereby acknowledge that by
            using the Service, you obtain no rights in the Site Content and/or the Trade Marks, or any part
            thereof. Under no circumstances may you use the Site Content and/or the Trade Marks without
            Bitkong’s prior written consent. Additionally, you agree not to do anything that will harm or
            potentially harm the rights, including the intellectual property rights of Bitkong.
          </li>
        </ul>
        <h3>2. No Warranties</h3>
        <ul>
          <li>
            2.1. Bitkong disclaims any and all warranties, expressed or implied, in connection with the
            service which is provided to you "as is" and we provide you with no warranty or representation
            whatsoever regarding its quality, fitness for purpose, completeness or accuracy.
          </li>
          <li>
            2.2. Regardless of Bitkong’s efforts, Bitkong makes no warranty that the service will be
            uninterrupted, timely or error-free, or that defects will be corrected.
          </li>
        </ul>
        <h3>3. Authority/Terms of Service</h3>
        <p>
          You agree to the game rules described on the Bitkong.com website. Bitkong retains authority over the
          issuing, maintenance, and closing of the Service. The decision of Bitkong’s management, concerning any
          use of the Service, or dispute resolution, is final and shall not be open to review or appeal.
        </p>
        <h3>4. Your Representations and Authoroties</h3>
        <p>
          Prior to your use of the Service and on an ongoing basis you represent, warrant, covenant and agree
          that:
        </p>
        <ul>
          <li>
            4.1. there is a risk of losing cryptocurrency when using the Service and that Bitkong has no
            responsibility to you for any such loss;
          </li>
          <li>4.2. your use of the Service is at your sole option, discretion and risk;</li>
          <li>
            4.3. you are solely responsible for any applicable taxes which may be payable on cryptocurrency
            awarded to you through your using the Service;
          </li>
          <li>
            4.5. the telecommunications networks and Internet access services required for you to access and
            use the Service are entirely beyond the control of Bitkong and Bitkong shall have no liability
            whatsoever for any outages, slowness, capacity constraints or other deficiencies affecting the
            same; and
          </li>
          <li>
            4.6. you are (i) aged 18 or over, (ii) you are of the age of majority in your jurisdiction,
            (iii) you are accessing the Service from a jurisdiction in which it is legal to do so, (iv) you
            are not currently self-excluded from any gambling site or gambling premises, and (v) that you
            will inform Bitkong immediately if you enter into a self-exclusion agreement with any gambling
            provider.
          </li>
        </ul>
        <h3>5. Prohibited Uses</h3>
        <ul>
          <li>
            5.1. PERSONAL USE. The Service is intended solely for the User’s personal use. The User is only
            allowed to wager for his/her personal entertainment and may not create multiple accounts,
            including for the purpose of collusion and/or abuse of service.
          </li>
          <li>
            5.3 JURISDICTIONS. Persons located in or residents of the United States, Czech Republic,
            Curaçao, Syria, Iran, North Korea or Australia (the ”Prohibited Jurisdictions”) are not
            permitted make use of the Service. For the avoidance of doubt, the foregoing restrictions on
            engaging in real-money play from Prohibited Jurisdictions applies equally to residents and
            citizens of other nations while located in a Prohibited Jurisdiction. Any attempt to circumvent
            the restrictions on play by any persons located in a Prohibited Jurisdiction or Restricted
            Jurisdiction, is a breach of this Agreement. An attempt at circumvention includes, but is not
            limited to, manipulating the information used by Bitkong to identify your location and providing
            Bitkong with false or misleading information regarding your location or place of residence.
          </li>
        </ul>
        <h3>6. Know your Customer (“KYC”)</h3>
        <p>
          Bitkong reserves the right, at any time, to ask for any KYC documentation it deems necessary to
          determine the identity and location of a User. Bitkong reserves the right to restrict service and
          payment until identity is sufficiently determined.
        </p>
        <h3>7. Breach</h3>
        <ul>
          <li>
            7.1. Without prejudice to any other rights, if a User breaches in whole or in part any provision
            contained herein, Bitkong reserves the right to take such action as it sees fit, including
            terminating this Agreement or any other agreement in place with the User and/or taking legal
            action against such User.
          </li>
          <li>
            7.2. You agree to fully indemnify, defend and hold harmless Bitkong and its shareholders,
            directors, agents and employees from and against all claims, demands, liabilities, damages,
            losses, costs and expenses, including legal fees and any other charges whatsoever, howsoever
            caused, that may arise as a result of: (i) your breach of this Agreement, in whole or in part;
            (ii) violation by you of any law or any third party rights; and (iii) use by you of the Service.
          </li>
        </ul>
        <h3>8. Limitations and Liability</h3>
        <ul>
          <li>
            8.1. Under no circumstances, including negligence, shall Bitkong be liable for any special,
            incidental, direct, indirect or consequential damages whatsoever (including, without limitation,
            damages for loss of business profits, business interruption, loss of business information, or
            any other pecuniary loss) arising out of the use (or misuse) of the Service even if bitkong.io had
            prior knowledge of the possibility of such damages.
          </li>
          <li>
            8.2. Nothing in this Agreement shall exclude or limit Bitkong’s liability for death or personal
            injury resulting from its negligence.
          </li>
        </ul>
        <h3>9. Disputes</h3>
        <p>
          If a User wishes to make a complaint, please contact Bitkong’s customer service team at
          support@bitkong.io. Should any dispute not be resolved to your satisfaction you may pursue remedies
          in the governing law jurisdiction set forth below.
        </p>
        <h3>10. Amendment</h3>
        <p>
          Bitkong reserves the right to update or modify this Agreement or any part thereof at any time or
          otherwise change the Service without notice and you will be bound by such amended Agreement upon
          posting. Therefore, we encourage you check the terms and conditions contained in the version of the
          Agreement in force at such time. Your continued use of the Service shall be deemed to attest to your
          agreement to any amendments to the Agreement.
        </p>
        <h3>11. Governing Law</h3>
        <p>
          The Agreement and any matters relating hereto shall be governed by, and construed in accordance with,
          the laws of Curaçao. You irrevocably agree that, subject as provided below, the courts of Curaçao
          shall have exclusive jurisdiction in relation to any claim, dispute or difference concerning the
          Agreement and any matter arising therefrom and irrevocably waive any right that it may have to
          object to an action being brought in those courts, or to claim that the action has been brought in
          an inconvenient forum, or that those courts do not have jurisdiction. Nothing in this clause shall
          limit the right of Bitkong to take proceedings against you in any other court of competent
          jurisdiction, nor shall the taking of proceedings in any one or more jurisdictions preclude the
          taking of proceedings in any other jurisdictions, whether concurrently or not, to the extent
          permitted by the law of such other jurisdiction.
        </p>
        <h3>12. Severability</h3>
        <p>
          If a provision of this Agreement is or becomes illegal, invalid or unenforceable in any jurisdiction,
          that shall not affect the validity or enforceability in that jurisdiction of any other provision
          hereof or the validity or enforceability in other jurisdictions of that or any other provision
          hereof.
        </p>
        <h3>13. Assignment</h3>
        <p>
          Bitkong reserves the right to assign this agreement, in whole or in part, at any time without notice.
          The User may not assign any of his/her rights or obligations under this Agreement.
        </p>
        <h3>14. Miscellaneous</h3>
        <ul>
          <li>
            14.1. No waiver by Bitkong of any breach of any provision of this Agreement (including the failure
            of Bitkong to require strict and literal performance of or compliance with any provision of this
            Agreement) shall in any way be construed as a waiver of any subsequent breach of such provision
            or of any breach of any other provision of this Agreement.
          </li>
          <li>
            14.2. Nothing in this Agreement shall create or confer any rights or other benefits in favour of
            any third parties not party to this Agreement.
          </li>
          <li>
            14.3. Nothing in this Agreement shall create or be deemed to create a partnership, agency, trust
            arrangement, fiduciary relationship or joint venture between you and Bitkong.
          </li>
          <li>
            14.4. Bitkong may assign, transfer, charge, sub-license, or deal in any other manner with this
            Agreement, or sub-contract any of its rights and obligations under this Agreement, to any other
            party.
          </li>
          <li>
            14.5. This Agreement constitutes the entire understanding and agreement between you and Bitkong
            regarding the Service and supersedes any prior agreement, understanding, or arrangement between
            you and Bitkong.
          </li>
        </ul>
      </div>
    </div>
  </b-container>
</template>
<script>
export default {
  name: "TermOfUse"
};
</script>
<style scoped>
h1 {
  font-size: 22px;
}

#content_text {
  color: #6e8899;
  font-size: 14px;
}

#content_text h2 {
  font-size: 18px;
}

#content_text h3 {
  font-weight: 400;
  font-size: 1.0625rem;
  color: rgb(255, 255, 255);
  margin: 10px 0;
}
</style>