import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters.js'
import mutations from './mutations'
import actions from './actions'
import Cookie from "vue-cookie";

Vue.use(Vuex);
const soundOpenCookie = Cookie.get('soundOpen');
const selectedTokenCookie = Cookie.get('selectedToken');
export default new Vuex.Store({
    state: {
        isLogged: null,
        userID: '0',
        telegramID: 0,
        email: '',
        avatar: 0,
        balance: {
            BTC: '0',
            BNB: '0',
            USDT: '0',
            TON: '0'
        },
        allBets: [],
        crash: {
            state: 'CONNECTION',
            time: new Date().getTime(),
            joined: false,
            showEscape: false,
            showEscapePhrase: 0,
            cashOutAmount: 0,
            allBets: {
                list: []
            },
            myBets: {
                list: []
            },
            history: {
                list: []
            },
            joined_bet_amount: 0,
            joined_bet_coin: '',
        },
        broadcastMessage: ['Welcome to BitKong.io - Start Free TON Mining Without any Investment...',
            'Multiply your TON with KING KONG game!!!'],
        isShowDeposit: false,
        isShowNotify: false,
        readNotification: true,
        soundOpen: (soundOpenCookie === null || soundOpenCookie === 'true'),
        selectedToken: (selectedTokenCookie === null ? 'TON' : selectedTokenCookie),
    },
    getters,
    mutations,
    actions
});