<template>
    <b-container id="content" style="max-width: 960px;">
        <div v-show="!loading">
            <div class="custom-body-referral">
                <div class="text-center" style="margin-top:20px;">
                    <b-button
                            :variant="(nav_type=='commission')?'success':'secondary'"
                            @click="nav_type='commission'"
                    >Commission Rates
                    </b-button>
                    <b-button
                            :variant="(nav_type=='statistic')?'success':'secondary'"
                            @click="nav_type='statistic'"
                            style="margin-left: 10px;"
                    >Statistic
                    </b-button>
                </div>

                <div style="max-width: 600px;margin: 0 auto;" v-show="nav_type=='commission'">
                    <table class="text-center" style="margin: 20px auto;">
                        <tbody>
                        <tr>
                            <th style="background: #465059;color: #FFFFFF;">YOUR REFERRAL URL</th>
                        </tr>
                        <tr>
                            <td style="padding: 10px;">
                                <div style=" position: relative;display: flex">
                                    <b-form-input v-model="linkReferral" readonly="readonly" id="referral-url"
                                                  style="border-radius: 0.25rem 0 0 0.25rem;background: #465059;color: #FFFFFF;border: none;"/>
                                    <button type="button" class="btn btn-success" style="border-radius: 0 0.25rem 0.25rem 0;"
                                            v-on:click="copyToClipboard('referral-url')">copy
                                    </button>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <p>
                        Simply promote your referral URL or share it with your friends, and anybody who signs up using
                        it
                        will be automatically registered as your referral and you will earn commissions whenever they
                        use the
                        website!
                    </p>

                    <table class="text-center" style="margin: 20px auto">
                        <tbody>
                        <tr>
                            <th
                                    style="background: #465059;color: #FFFFFF;"
                                    colspan="2"
                            >REFERRAL COMMISSION RATES
                            </th>
                        </tr>
                        <tr>
                            <td>Faucet</td>
                            <td>50% of base prize</td>
                        </tr>
                        <tr>
                            <td>King Kong</td>
                            <td>0.25% of wager / bet</td>
                        </tr>
                        <tr>
                            <td>Survey</td>
                            <td>10% of a completed survey</td>
                        </tr>
                        </tbody>
                    </table>

                </div>

                <div style="max-width: 600px;margin: 0 auto;" v-show="nav_type=='statistic'">
                    <table class="text-center" style="margin: 20px auto">
                        <tbody>
                        <tr>
                            <th
                                    style="background: #465059;color: #FFFFFF;"
                                    colspan="2"
                            >REFERRAL COMMISSIONS EARNED
                            </th>
                        </tr>
                        <tr>
                            <td>Faucet</td>
                            <td>{{ (referral_faucet_ton).toFixed(8) }} TON</td>
                        </tr>
                        <tr>
                            <td>King Kong</td>
                            <td>{{ (referral_kong_usd).toFixed(8) }} TON</td>
                        </tr>
                        <tr>
                            <td>Surveys</td>
                            <td>{{ (referral_survey_ton).toFixed(8) }} TON</td>
                        </tr>


                        </tbody>
                    </table>
                    <p>
                        Your referral commissions are added to your main account balance (at the top right) in real
                        time,
                        as and when you earn them. The numbers below are just for reference and show the commissions
                        that you
                        have accumulated over time.
                    </p>
                    <p>
                        Commissions for the King Kong game are only calculated and credited for every 0.05 TON
                        that
                        your
                        referred users wager. If they wager lower amounts, these are recorded in our database but
                        commissions
                        are only credited at increments of 0.05 TON wagered by each individual user.
                    </p>
                    <p>Referral statistics are updated hourly.</p>

                    <table class="text-center" style="margin: 20px auto;">
                        <tbody>
                        <tr>
                            <th style="background: #465059;color: #FFFFFF;">TOTAL REFERRALS</th>
                        </tr>
                        <tr>
                            <td>{{ total_users }}</td>
                        </tr>
                        </tbody>
                    </table>

                    <div class="custom-title" style="border-radius: 0;">REFERRAL USERS</div>
                    <b-table
                            class="referral_users"
                            :items="users"
                            :fields="referralUsersFields"
                            :responsive="true"
                            :dark="true"
                            style="font-size: 12px"
                            bordered
                            show-empty
                    >
                        <template v-slot:empty>
                            <div class="text-center">No more results</div>
                        </template>
                        <template v-slot:cell(createdAt)="data">{{ timeStampToString(data.item.createdAt) }}</template>
                    </b-table>
                    <div class="text-center">
                        <el-pagination
                                layout="prev, pager, next"
                                @current-change="changePage"
                                :current-page="currentPage"
                                :page-size="10"
                                :total="total_users"
                        ></el-pagination>
                    </div>

                </div>
            </div>
        </div>
        <div v-show="loading" style="padding: 30px;" class="text-center">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </b-container>
</template>
<style>
.referral_users table.b-table {
    background: #293946;
}
</style>
<style scoped>
.referral_users {
    max-width: 600px;
    margin: 0 auto;
}

.custom-body-referral {
    color: #ffffff;
}

.custom-body-referral table {
    background: #293946;
    color: #ffffff;
    width: 100%;
}

.custom-body-referral table,
.custom-body-referral table th,
.custom-body-referral table td {
    border: 1px solid rgb(70, 80, 89);
    border-collapse: collapse;
}

.custom-body-referral table th,
.custom-body-referral table td {
    padding: 10px 0;
}
</style>
<script>
import Service from "../../services/Service";
import Config from "../../config";

export default {
    name: "Affiliate",
    data() {
        return {
            nav_type: "commission",
            users: [],
            total_users: 0,
            currentPage: 1,
            referralUsersFields: [
                {key: "user_id", label: "ID", class: "text-center"},
                {key: "createdAt", label: "Registered Time", class: "text-center"},
                {key: "level", label: "Level", class: "text-center"},
                {key: "faucet_claims", label: "Faucet Claims", class: "text-center"}
            ],
            referral_faucet_ton: 0,
            referral_kong_usd: 0,
            referral_survey_ton: 0,
            loading: true,
            banner: {
                "728x90": "https://cdn.blackgame.io/dashboard/banner/728x90.jpg",
                "300x600": "http://cdn.blackgame.io/dashboard/banner/300x600.jpg",
                "300x250": "http://cdn.blackgame.io/dashboard/banner/300x250.jpg",
                "250x250": "http://cdn.blackgame.io/dashboard/banner/250x250.jpg"
            }
        };
    },
    async created() {
        await this.getReferralEarned();
        await this.getReferralUsers();
        this.loading = false;
    },
    computed: {
        userID() {
            return this.$store.getters.userID;
        },
        telegramID(){
            return this.$store.getters.telegramID;
        },
        linkReferral() {
            return `${Config.REFERRAL_URL}?start=${this.userID}`;
        },
        forumCode() {
            return `[center][url=${this.linkReferral}][b][size=15pt][color=red]Win Free Bitcoins every hour! - [/color][color=green]blackgame.io[/color][/size][/b][/url][/center]`;
        }
    },
    methods: {
        async changePage(page) {
            this.currentPage = page;
            await this.getReferralUsers();
        },
        async getReferralUsers() {
            const res = await Service.getReferral({
                page: this.currentPage
            }).catch(() => {
            });
            if (res !== undefined && res.data.success) {
                this.users = res.data.list;
                this.total_users = res.data.total;
            }
        },
        async getReferralEarned() {
            const earned = await Service.getReferralEarned().catch(() => {
            });
            if (earned !== undefined) {
                this.referral_survey_ton = earned.data.referral_survey_ton;
                this.referral_faucet_ton = earned.data.referral_faucet_ton;
                this.referral_kong_ton = earned.data.referral_kong_usd;
            }
        },
        copyToClipboard: function (selector) {
            const id = `#${selector}`
            const copyAddress = document.querySelector(id);
            copyAddress.setAttribute("type", "text");
            copyAddress.select();
            try {
                document.execCommand("copy");
            } catch (err) {
                this.$swal("Copy to clipboard", "Oops, unable to copy", "error");
            }
            this.$toastr.s('Copied!');
            window.getSelection().removeAllRanges();
        },
        timeStampToString: function (unix_timestamp) {
            const date = new Date(unix_timestamp);
            return (
                ("0" + date.getDate()).slice(-2) +
                "/" +
                ("0" + (date.getMonth() + 1)).slice(-2) +
                "/" +
                date.getFullYear() +
                " " +
                ("0" + date.getHours()).slice(-2) +
                ":" +
                ("0" + date.getMinutes()).slice(-2)
            );
        }
    }
};
</script>