<template>
    <b-container id="content">
        <div style="margin: 0 auto;background: #22303b;padding: 20px;border-radius: 5px;">
            <h1>Privacy policy</h1>
            <div id="content_text">
                <p>
                    This is the privacy policy of Medium Rare N.V. (owner and operator of Stake.) This Privacy Policy
                    sets
                    out how bitkong.io will manage your personal information which we collect as a result of your
                    membership
                    of bitkong.io, your use of our websites located at https://bitkong.io and any pages or websites
                    under the
                    'Stake' brand including any mobile phone or tablet applications that are owned and/or operated by us
                    or
                    whenever we otherwise deal with you. We are committed to ensuring that the personal information we
                    collect about you is protected and is used, stored and disclosed in accordance with the Privacy Act
                    and
                    this Privacy Policy.</p>
                <h3>1. Website Use</h3>
                <p>In using the website, you agree to be bound by the terms of this Privacy Policy. bitkong.io may
                    review
                    the
                    Privacy Policy periodically and reserves the right to change the Privacy Policy at any time at its
                    discretion by posting such changes on the website. Changes willblack be effective immediately when posted
                    on
                    the website. Your continued use of the website following posting will constitute your acceptance of
                    those changes.</p>
                <p>This Privacy Policy will not apply to websites which are linked to the website and over which
                    bitkong.io
                    has no control.</p>
                <h3>2. Personal Information</h3>
                <p>The kinds of Personal Information that bitkong.io may collect from you is:
                <ul>
                    <li>Name</li>
                    <li>Email address</li>
                    <li>Personally submitted preferences</li>
                    <li>Location data</li>
                    <li>IP address</li>
                </ul>
                </p>

                <h3>3. Collection Purposes</h3>
                <p>bitkong.io will collect your Personal Information only by lawful and fair means and not in an
                    intrusive
                    way to operate its business as a licensed online wagering bookmaker for the following purposes:</p>
                <ul>
                    <li>If you wish to subscribe to bitkong.io's weekly newsletter.</li>
                    <li>To process your application to become a member of Blackgame.</li>
                    <li>To provide services to you as a Member.</li>
                    <li>To identify you as a Member for security purposes and to comply with our legal obligations.</li>
                    <li>To maintain your Member account.</li>
                    <li>To upgrade and enhance your experience within the website or over the telephone, or to tailor or
                        develop information, services or products to suit your needs which may include market research
                        and
                        conducting promotions.
                    </li>
                    <li>To tell you about our products or services that we think may be of interest to you by
                        communicating
                        with you via email, SMS or telephone.
                    </li>
                    <li>To create aggregate data about Members through demographic profiling, statistical analysis of
                        the
                        database to provide to potential and existing BlackGameholders, and to allow for more efficient
                        operation of bitkong.io’s business.
                    </li>
                    <li>To determine Members' liability to pay goods and services tax and other taxes where
                        applicable.
                    </li>
                    <li>To comply with bitkong.io’s legal and statutory obligations.</li>
                    <li>For taking appropriate action if bitkong.io has reason to suspect that unlawful activity or
                        misconduct of a serious nature has been, is being or may be engaged in that relates to our
                        functions
                        and activities.
                    </li>
                    <li>To establish, exercise or defend any legal claims.</li>
                    <li>Manage job applications.</li>
                </ul>
                <p>You may choose to deal with us on an anonymous basis or to use a pseudonym unless it is not
                    practicable
                    for us to deal with individuals who we cannot identify or we are required by law to only deal with
                    identified individuals. Also, if you do not provide bitkong.io with the Personal Information we
                    request,
                    we may be unable to process your application to become a Member, provide you with our services or
                    respond to your enquiry or complaint. Examples of Personal Information that may be collected by
                    bitkong.io include your name, email address, telephone numbers, your date of birth, other
                    verification
                    information such as your betting activities. By becoming a Member or otherwise choosing to provide
                    bitkong.io with Personal Information you consent to bitkong.io collecting, using and disclosing
                    your
                    Personal Information for the above purposes.</p>
                <h3>4. Direct Marketing and Opting Out</h3>
                <p>From time to time we may use your Personal Information to inform you about bitkong.io's products or
                    services or about promotional activities which bitkong.io believes may be of interest or of
                    benefit to
                    you. We may do this via email, SMS, telephone or mail. If you no longer wish to receive marketing or
                    promotional material from bitkong.io at all or in any particular form, you may contact us at any
                    time
                    by
                    email to support@bitkong.io with your request with which we will comply as soon as is
                    practical.</p>
                <p>From time to time we may contact you in relation to the management and administration of
                    your bitkong.io
                    account. These communications can be via any of the modes of contact recorded when registering as a
                    Member. Such communication does not affect your opt-in or opt-out status for direct marketing
                    communications.</p>
                <h3>5. Management of your Personal Information</h3>
                <p>bitkong.io will take all reasonable steps to ensure that the Personal Information which it
                    collects,
                    uses
                    or discloses is correct and is stored in a secure environment which is accessed only by authorised
                    persons.</p>
                <p>bitkong.io will destroy or permanently de-identify the Personal Information we hold when it is no
                    longer
                    required for any purpose permitted under the APPs including our legal or operational
                    obligations.</p>
                <h3>6. Security of Personal Information</h3>
                <p>You acknowledge that no data transmission over the Internet is totally secure.
                    Accordingly,bitkong.io
                    does
                    not warrant the security of any information which you transmit to it. Any information which you
                    transmit
                    to bitkong.io is transmitted at your own risk. However, once bitkong.io receives your
                    transmission,
                    bitkong.io will take reasonable steps to protect your Personal Information from misuse, loss and
                    unauthorised access, modification and disclosure including by using password protected systems and
                    databases and Secure Socket Layer (SSL) technology.</p>
                <p>bitkong.io’s employees, agents and contractors are required to maintain the confidentiality of
                    Members'
                    Personal Information and trading behaviour.</p>
                <p>Information posted on bulletin boards or communicated within a social media environment (for example,
                    Facebook, Twitter, Chat Rooms) becomes public information. bitkong.io cannot guarantee the
                    security of
                    this type of disclosed information.</p>
                <p>We take seriously the responsibility to exclude children from access to our services. We will not
                    accept
                    their information for the purposes of opening a wagering account. It is however, ultimately the
                    responsibility of parents or guardians to monitor their children’s Internet activities including
                    where
                    appropriate by using Internet screening software.</p>
                <p>Remember to always log out of your account when you have completed your time on the website. This is
                    particularly important if you share a computer with another person. You are responsible for the
                    security
                    of and access to your own computer, mobile device or any other handset used to access the
                    website.</p>
                <p>Ultimately, you are solely responsible for maintaining the secrecy of your username, password and any
                    account information. Please be careful whenever using the Internet and our website.</p>
                <h3>7. Access to Personal Information</h3>
                <p>You may access the Personal Information collected by bitkong.io by following the “Settings” link on
                    the
                    website or by contacting us on support@bitkong.io. We will give you access to your Personal
                    Information
                    in the manner requested if that is possible and within a reasonable period. If we refuse your
                    request or
                    cannot give you access in the manner you have requested, we will do what we can to meet your
                    requirements by other means. We may not be required to give you access to your Personal Information
                    in
                    certain circumstances which are set out in the APPs including where it may have an unreasonable
                    impact
                    on other individual's privacy. If we refuse access for such reasons, we will advise you in writing
                    of
                    the refusal and our reasons and the complaint mechanisms available to you.</p>
                <h3>8. Use of Website</h3>
                <p>By using this website, you agree to bitkong.io collecting and processing your Personal Information
                    on
                    the
                    basis explained in this Privacy Policy.</p>
                <h3>9. Contact Details</h3>
                <p>If you have any queries, requests for access or correction or complaints relating to the handling of
                    your
                    personal information, please contact us by email or by the site customer support chat.</p>
                <p><strong>Email:</strong> <a href="mailto:support@bitkong.io">support@bitkong.io</a></p>
                <h3>10. Updates to this Privacy Policy</h3>
                <p>bitkong.io may review, change and update this Privacy Policy from time to time reflect our current
                    practices and obligations. We will publish our current Privacy Policy on our website at
                    www.bitkong.io
                    and the changes will take effect at the time of publishing. You should review this privacy policy
                    regularly and remain familiar with its terms.</p>
            </div>
        </div>
    </b-container>
</template>
<script>
    export default {
        name: 'PrivacyPolicy',
    }
</script>
<style scoped>
    h1 {
        font-size: 22px;
    }

    #content_text {
        color: #6e8899;
        font-size: 14px;
    }

    #content_text h2 {
        font-size: 18px;
    }

    #content_text h3 {
        font-weight: 400;
        font-size: 1.0625rem;
        color: rgb(255, 255, 255);
        margin: 10px 0;
    }
</style>