export default {
    isLogged: state => state.isLogged,
    userID: state => state.userID,
    telegramID: state => state.telegramID,
    balance: state => state.balance,
    email: state => state.email,
    avatar: state => state.avatar,
    allBets: state => state.allBets,
    crash: state => state.crash,
    broadcastMessage: state => state.broadcastMessage,
    isShowDeposit: state => state.isShowDeposit,
    isShowNotify: state => state.isShowNotify,
    readNotification: state => state.readNotification,
    soundOpen: state => state.soundOpen,
    selectedToken: state => state.selectedToken
}