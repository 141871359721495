<template>
  <b-container id="content">
    <div v-show="!loading">
      <div style="max-width: 600px;margin: 20px auto;">
        <table class="table table-bordered text-center">
          <tbody>
            <tr>
              <td
                colspan="2"
                style="background: rgb(70, 80, 89) none repeat scroll 0% 0%;text-transform: uppercase;color: #FFFFFF;font-weight: bold;"
              >Profile</td>
            </tr>
            <tr>
              <td id="user-id">USER ID</td>
              <td style="background: #293946;color: #FFFFFF">{{userID}}</td>
            </tr>
            <tr>
              <td id="email">LEVEL</td>
              <td style="background: #293946;color: #FFFFFF">{{levels_name[level]}}</td>
            </tr>
            <tr>
              <td id="email">KING KONG</td>
              <td
                style="background: #293946;color: #FFFFFF"
              >{{kong_bets}} bets</td>
            </tr>
          </tbody>
        </table>

        <div class="text-center mt-5" v-show="level_target!==undefined">
          <div
            class="mt-3"
            style="background: rgb(70, 80, 89) none repeat scroll 0% 0%;text-transform: uppercase;color: rgb(255, 255, 255);font-weight: bold;padding: 0.75rem;"
          >LEVEL</div>
          <div
            style="background: rgb(41, 57, 70) none repeat scroll 0% 0%;color: rgb(255, 255, 255);padding: 0.75rem;border: 1px solid rgb(70, 80, 89);"
          >
            <div>Wagered: {{(level_wagered/1e8).toFixed(8)}} TON/ Target: {{(level_target/1e8).toFixed(8)}} TON</div>
            <b-progress :max="level_target" class="mt-3 mb-3">
              <b-progress-bar
                :value="level_wagered"
                :label="`${Math.floor((level_wagered / level_target) * 100)}%`"
              ></b-progress-bar>
            </b-progress>
            <div style="height: 30px;">
              <div style="float: left">{{levels_name[level]}}</div>
              <div style="float: right">{{levels_name[level+1]}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="loading" style="padding: 30px;" class="text-center">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </b-container>
</template>
<script>
import Service from "../../services/Service";
export default {
  name: "Profile",
  data() {
    return {
      email: "",
      level: 0,
      loading: true,
      level_wagered: 0,
      level_target: 0,
      levels_name: [
        "Iron",
        "Bronze",
        "Silver",
        "Gems",
        "Titanium",
        "Gold",
        "Platinum",
        "Ruby",
        "Diamond"
      ],
      multiply_bets: 0,
      multiply_sum_bets: 0,
      crash_bets: 0,
      crash_sum_bets: 0,
      kong_bets: 0,
      kong_sum_bets: 0
    };
  },
  async created() {
    this.loading = true;
    await this.getProfile();
    this.loading = false;
  },
  methods: {
    async getProfile() {
      const res = await Service.getProfile().catch(() => {});
      if (res !== undefined && res.data.success) {
        const profile = res.data;
        this.email = profile.user.email;
        this.level = profile.user.level;
        this.level_wagered = profile.user.wagered;
        this.level_target = profile.user.level_target;
        this.multiply_bets = profile.user.multiply_bets;
        this.multiply_sum_bets = profile.user.multiply_sum_bets;
        this.crash_bets = profile.user.crash_bets;
        this.crash_sum_bets = profile.user.crash_sum_bets;
        this.kong_bets = profile.user.kong_bets;
        this.kong_sum_bets = profile.user.kong_sum_bets;
      }
    }
  },
  computed: {
    userID() {
      return this.$store.getters.userID;
    }
  }
};
</script>
<style scoped>
td#user-id,
td#email,
td#btc-address {
  background: #293946 none repeat scroll 0% 0%;
  color: #ffffff;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid rgb(70, 80, 89);
}
</style>