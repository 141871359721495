
module.exports = {
    API_URL: process.env.VUE_APP_API_URL,
    SOCKET_URL: process.env.VUE_APP_SOCKET_URL,
    GOOGLE_RECAPTCHA_KEY: process.env.VUE_APP_GOOGLE_RECAPTCHA_KEY,
    FRONTEND_URL: process.env.VUE_APP_FRONTEND_URL,
    CDN_URL: process.env.VUE_APP_CDN_URL,
    BITCOIN_EXPLORER_LINK: process.env.VUE_APP_BITCOIN_EXPLORER_LINK,
    BSC_EXPLORER_LINK: process.env.VUE_APP_BSC_EXPLORER_LINK,
    TON_EXPLORER_LINK: process.env.VUE_APP_TON_EXPLORER_LINK,
    BITCOIN_EXPLORER_ADDRESS_LINK: process.env.VUE_APP_BITCOIN_EXPLORER_ADDRESS_LINK,
    BSC_EXPLORER_ADDRESS_LINK: process.env.VUE_APP_BSC_EXPLORER_ADDRESS_LINK,
    TON_EXPLORER_ADDRESS_LINK: process.env.VUE_APP_TON_EXPLORER_ADDRESS_LINK,
    REFERRAL_URL:process.env.VUE_APP_REFERRAL_URL,

    KONG_GAME: {
        MIN_AMOUNT: {
            BTC: 10,
            BNB: 1000, // 0.01 BNB
            USDT: 10000, // 0.1 USDT
            TON: 1000 // 0.1 USDT
        },
        MAX_AMOUNT: {
            BTC: 100000000, // 1 BTC
            BNB: 10000000000, // 100 BNB
            USDT: 100000000000, // 1000 USDT
            TON: 10000000000, // 100 TON
        }
    },
    NUMBER_OF_DECIMAL_PLACES: {
        BTC: 8,
        BNB: 8,
        USDT:8,
        TON: 8,
    },
    MIN_WITHDRAW: {
        BTC: 30000,
        BNB: 3000000,
        USDT: 1000000000,
        TON: 50000000
    },
    WITHDRAWAL_FEE: {
        BTC: 5000,
        BNB: 300000,
        USDT: 100000000,
        TON: 100000,
    },
    REQUIRES_CONFIRMATION: {
        BTC: 3,
        BNB: 10,
        USDT: 10,
        TON: 1,
    },
    LEVEL_SYSTEM_PRIZE: [6500, 12500, 25000, 75000, 150000,750000, 5000000, 100000000,200000000],
};