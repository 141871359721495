export default {
    setLogged({ commit }, isLogged) {
        commit('setLogged', isLogged)
    },
    setUserID({ commit }, userID) {
        commit('setUserID', userID)
    },
    setTelegramID({commit}, telegramID){
        commit('setTelegramID',telegramID)
    },
    setEmail({ commit }, email) {
        commit('setEmail', email);
    },
    setAvatar({ commit }, avatar) {
        commit('setAvatar', avatar);
    },
    setBalance({ commit }, balance) {
        commit('setBalance', balance)
    },
    setAllBets({ commit }, allBets) {
        commit('setAllBets', allBets)
    },
    setCrash({ commit }, crash) {
        commit('setCrash', crash)
    },
    setCrashHistory({ commit }, crash) {
        commit('setCrashHistory', crash)
    },
    setCrashMyBet({ commit }, crash) {
        commit('setCrashMyBet', crash)
    },
    setCrashAllBet({ commit }, crash) {
        commit('setCrashAllBet', crash)
    },
    setCrashState({ commit }, crash_state) {
        commit('setCrashState', crash_state)
    },
    setIsShowDeposit({ commit }, obj) {
        commit('setIsShowDeposit', obj);
    },
    setIsShowNotify({ commit }, obj) {
        commit('setIsShowNotify', obj);
    },
    setReadNotification({ commit }, obj) {
        commit('setReadNotification', obj);
    },
    setSoundOpen({ commit }, obj) {
        commit('setSoundOpen', obj);
    },
    setSelectedToken({ commit }, obj) {
        commit('setSelectedToken', obj);
    },
    SOCKET_multiply({ commit }, data) {
        commit('socketMultiply', data)
    },
    SOCKET_kingkong({ commit }, data) {
        commit('socketKingKong', data)
    },
    SOCKET_mining({ commit }, data) {
        commit('socketMining', data)
    },
    SOCKET_crash({ commit }, obj) {
        commit('socketCrash', obj);
    },
    SOCKET_broadcast_message({ commit }, obj) {
        commit('socketBCMsg', obj);
    },
    SOCKET_video_poker({ commit }, obj) {
        commit('socketVideoPoker', obj);
    },
    SOCKET_keno({ commit }, obj) {
        commit('socketKeno', obj);
    },
}