<template>
    <b-container id="content" style="color: #a0b5c9;">
        <div>
            <div class="text-center" style="margin: 20px 0;font-size: 20px;text-transform: uppercase;color: #ffffff">
                EARN FREE TON
            </div>

            <div style="max-width: 700px;margin: 0 auto;padding: 0 0 20px 0;line-height: 30px;color: #FFFFFF;"
                 class="text-center">
                To receive rewards from Hourly TON, please fill the correct captcha and press CLAIM button. The amount
                of free TON that you earn will depend on your level and paid out according to the table below.
                Your level is <b style="font-size: 18px">{{ levels_name[level] }}</b> to reach the next level please
                play our <a href="/kingkong">KingKong</a> game.
            </div>

            <div class="level-container" v-show="level_target !== undefined">
                <div>Wagered: {{ (level_wagered / 1e8).toFixed(8) }}  TON/ Target: {{
                    (level_target / 1e8).toFixed(8)
                    }}
                     TON
                </div>
                <b-progress :max="level_target" class="mt-3 mb-3" style="height: 15px;">
                    <b-progress-bar :value="level_wagered"
                                    :label="`${Math.floor((level_wagered / level_target) * 100)}%`"></b-progress-bar>
                </b-progress>
                <div style="height: 30px;">
                    <div style="float: left">{{ levels_name[level] }}</div>
                    <div style="float: right">{{ levels_name[level + 1] }}</div>
                </div>
            </div>

            <div class="text-center" style="margin: 10px 0;font-size: 20px;color: #ffffff">
                Take <a href="/survey">Surveys</a> to earn more TON and increase your level.
            </div>
            <div id="onerow"></div>


            <iframe width="100%" frameBorder="0" height="500px"
                    :src=cpx_url_survey></iframe>
            <div id="history">
                <div class="text-center"
                     style="margin: 20px 0;font-size: 20px;text-transform: uppercase;color: #ffffff;">
                    LEVEL SYSTEM
                </div>

                <div v-if="nav_type === 'level'">
                    <div>
                        <b-table :items="levels" bordered :responsive="true" :dark="true"
                                 style="font-size: 18px;text-align: center;">
                            <template v-slot:cell(level)="data">
                                <span :class="{ 'text-success': data.index === level }">{{
                                    (levels_name[data.index])
                                    }}
                                </span>
                            </template>
                            <template v-slot:cell(reward)="data">
                                <span :class="{ 'text-success': data.index === level }">{{
                                    (data.item.reward/1e8).toFixed(8) + ' TON'
                                    }}</span>
                            </template>
                        </b-table>
                    </div>
                </div>
            </div>

            <div style=" display:flex; align-items: center; flex-direction: column">
                            <MiningCountdown :seconds="remaining_time" v-if="remaining_time > 0 " />
                <b-button v-if="remaining_time<=0"
                        type="submit"
                        variant="success"
                        id="btn-change-password"
                        style="width: 30%;"
                        @click="claim"
                >CLAIM
                </b-button>
            </div>
        </div>
    </b-container>
</template>

<script>
import Service from "../services/Service";
import Config, {LEVEL_SYSTEM_PRIZE} from "../config";
import MiningCountdown from "@/components/Countdown.vue";
// import VueRecaptcha from "vue-recaptcha";
// import EventBus from "../event-bus";
// import VueRecaptcha from "vue-recaptcha";
const script1 = {
    div_id: "onerow", // string // Entry point for the script
    theme_style: 7, // int // Theme: Select 1 for fullscreen, 2 for sidebar, 3 for sidebar single item 7 = onerow
    order_by: 1,// int // Sort surveys (optional): Select 1 for best score (default), 2 for best money, 3 for best conversion rate
    display_mode: 3 ,
    limit_surveys:8 // int // Limit the number of surveys displayed (optional). Default is 12.
};


export default {
    name: "Faucet",
    components: {
        MiningCountdown
        // VueRecaptcha
    },
    data() {
        return {
            recaptcha_require: false,
            sitekey: Config.GOOGLE_RECAPTCHA_KEY,
            level: 0,
            user_id: '0',
            table_loading: true,
            currentPage: 1,
            total: 0,
            turnsSelected: "1d",
            buyLoading: false,
            phrase: 1,
            miner_image: "https://cdn.blackgame.io/dashboard/miner/1.gif",
            running: false,
            remaining_time: 0,
            prize: 0,
            mining_turn: 0,
            num_of_phrase: 2,
            loading: true,
            all_mining_items: [],
            items: [],
            all_mining_fields: [
                {
                    key: "user_id",
                    label: "User",
                    class: "text-center"
                },
                {
                    key: "prize",
                    label: "Prize",
                    class: "text-center"
                },
                {
                    key: "timestamp",
                    label: "Time",
                    class: "text-center"
                }
            ],
            fields: [
                {
                    key: "timestamp",
                    label: "Time",
                    class: "text-center"
                },
                {
                    key: "prize",
                    label: "Prize",
                    class: "text-center"
                }
            ],
            nav_type: "level",
            preload_images: [],
            levels: [
                {
                    level: "Level 0",
                    reward: LEVEL_SYSTEM_PRIZE[0],
                },
                {
                    level: "Level 1",
                    reward: LEVEL_SYSTEM_PRIZE[1],
                },
                {
                    level: "Level 2",
                    reward: LEVEL_SYSTEM_PRIZE[2],
                },
                {
                    level: "Level 3",
                    reward: LEVEL_SYSTEM_PRIZE[3],
                },
                {
                    level: "Level 4",
                    reward: LEVEL_SYSTEM_PRIZE[4],
                },
                {
                    level: "Level 5",
                    reward: LEVEL_SYSTEM_PRIZE[5],
                },
                {
                    level: "Level 6",
                    reward: LEVEL_SYSTEM_PRIZE[6],
                },
                {
                    level: "Level 7",
                    reward: LEVEL_SYSTEM_PRIZE[7],
                },
                {
                    level: "Level 8",
                    reward:LEVEL_SYSTEM_PRIZE[8]
                }
            ],
            levels_name: [
                "Iron",
                "Bronze",
                "Silver",
                "Gems",
                "Titanium",
                "Gold",
                "Platinum",
                "Ruby",
                "Diamond"
            ],
            level_wagered: 0,
            level_target: 0,
            cpx_url_survey:'',
        };
    },
    async created() {
        // await this.getProfile();
        await this.getFaucetInfo();
    },
    async mounted() {
        this.user_id = this.$store.getters.userID
        this.cpx_url_survey =`https://offers.cpx-research.com/index.php?app_id=23945&ext_user_id=${this.user_id}`
        this.cpxSurvey();
    },
    methods: {
        async claim(evt) {
            evt.preventDefault();
            if (this.remaining_time > 0) {
                return;
            }
            const res = await Service.faucetClaim().catch(() => {
            });
            if (res !== undefined && res.data.success) {
                await this.$store.dispatch("setBalance", res.data.balance);
                 await this.getFaucetInfo();
                this.$toastr.s(res.data.message);
            } else {
                if (res.data.success === false) {
                    this.$toastr.s(res.data.message);
                }
            }

        },


        changePage(page) {
            this.currentPage = page;
            // this.getMiningHistory();
        },
        countDown() {
            let call_info = true;
            if (this.remaining_time <= 0) {
                call_info = false;
            }
            clearInterval(this.interval);
            this.interval = setInterval(() => {
                this.remaining_time -= 1;
                if (this.remaining_time <= 0) {
                    clearInterval(this.interval);
                    if (call_info) this.getFaucetInfo();
                }
            }, 1000);
        },
        async getFaucetInfo() {
            this.loading = true;
            const res = await Service.faucetInfo().catch(() => {
            });
            this.loading = false;
            if (res !== undefined && res.data.success) {
                this.level = res.data.level;
                this.level_wagered = res.data.level_wagered;
                this.level_target = res.data.level_target;
                this.remaining_time = res.data.last_claim +3600 - res.data.current_timestamp;

                this.remaining_time = this.remaining_time < 0 ? 0 : this.remaining_time;
                this.countDown();
            }
        },
        // async getMiningHistory() {
        //     this.table_loading = true;
        //     const res = await Service.miningHistory({
        //         page: this.currentPage
        //     }).catch(() => {
        //     });
        //     this.table_loading = false;
        //     if (res !== undefined && res.data.success) {
        //         this.items = res.data.list;
        //         this.total = res.data.total;
        //     }
        // },
        async getAllMiningHistory() {
            this.table_loading = true;
            const res = await Service.allMiningHistory({}).catch(() => {
            });
            this.table_loading = false;
            if (res !== undefined && res.data.success) {
                this.all_mining_items = res.data.list;
            }
        },
        timeStampToString: function (unix_timestamp) {
            const date = new Date(unix_timestamp);
            return (
                ("0" + date.getDate()).slice(-2) +
                "/" +
                ("0" + (date.getMonth() + 1)).slice(-2) +
                "/" +
                date.getFullYear() +
                " " +
                ("0" + date.getHours()).slice(-2) +
                ":" +
                ("0" + date.getMinutes()).slice(-2)
            );
        },
        getTimeByLevel(level) {
            if (level === 1) return 55 * 60;
            if (level === 2) return 50 * 60;
            if (level === 3) return 45 * 60;
            if (level === 4) return 30 * 60;
            if (level === 5) return 15 * 60;
            if (level === 6) return 10 * 60;
            if (level === 7) return 5 * 60;
            if (level === 8) return 60;
            return 60 * 60;
        },
        cpxSurvey() {
            const config = {
                general_config: {
                    app_id: 23945, // replace your app_id
                    ext_user_id:this.user_id, // replace the userid from your user
                    subid_1: "", // string
                    subid_2: "", // string
                },
                style_config: {
                    text_color: "#2b2b2b", // string // hex, rgba, colorcode
                    survey_box: {
                        topbar_background_color: "#1DA05E", // string // hex, rgba, colorcode
                        box_background_color: "white", // string // hex, rgba, colorcode
                        rounded_borders: true, // boolean true || false
                        stars_filled: "#ffaf20", // string // hex, rgba, colorcode
                        stars_empty: "rgb(221 221 221)", // string, rgb, colorcode
                        accent_color_small_box: "#1DA05E", // string, rgb, colorcode defines the accent color for the row box layout
                        place_stars_bottom_small_box: true // default: false (stars are top)
                    },
                },
                script_config: [script1], // Object Array
                debug: false, // boolean
                useIFrame: true, //boolean
                iFramePosition: 3 // 1 right (default), 2 left // 3 = center
            };
            window.config = config;
            let cpxResearchScript = document.createElement('script');
            cpxResearchScript.setAttribute('src', 'https://cdn.cpx-research.com/assets/js/script_tag_v2.0.js');
            cpxResearchScript.setAttribute('id', 'cpx-script');
            document.head.appendChild(cpxResearchScript);
        }
    },
    beforeDestroy() {
        clearInterval(this.interval);
    },
    computed: {
        // WAITING_TIME() {
        //     return this.getTimeByLevel(this.level);
        // },
        CDN_URL() {
            return Config.CDN_URL;
        }
    }
};
</script>
<style>
.btn:hover,
.btn:focus {
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
}

.recaptcha-require {
    opacity: 0.4;
}

.recaptcha-form {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    text-align: center;
}

.recaptcha-form > div > div {
    margin: 0 auto;
}
</style>
<style scoped>
*:hover,
*:active,
*:focus {
    outline: 0;
}

.level-container {
    padding: 10px;
    max-width: 700px;
    margin: 0 auto;
    text-align: center;
}

.buy-button {
    background: linear-gradient(264deg, #f7da42, #ffbc34, #f7da42);
    color: #fff;
    border: none;
    padding: 10px 50px;
    width: 100%;
    border-radius: 5px;
    font-weight: bold;
    min-width: 180px;
    transition: all 0.5s;
}

.buy-button.disable {
    opacity: 0.5;
    -webkit-transition: opacity 0.5s;
    transition: opacity 0.5s;
    cursor: not-allowed;
}

#history {
    max-width: 700px;
    margin: 0 auto;
    font-size: 14px !important;
}

.xtabs-navs {
    width: 260px;
    margin: 30px auto;
    height: 2.25rem;
    border: none;
    background-color: transparent;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.xtabs-navs .xtabs-nav {
    height: 2.25rem;
    font-size: 0.875rem;
    display: inline-block;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding: 0;
    width: 100%;
    line-height: 1.9375rem;
    cursor: pointer;
    position: relative;
    z-index: 2;
    text-align: center;
    white-space: nowrap;
}

.xtabs-navs .xtabs-nav.active {
    background-image: none;
    color: #ffe22b;
    position: relative;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}

.xtabs-nav .xtabs-label {
    color: #afa893;
    display: inline-block;
    font-size: 0.875rem;
    line-height: 1.9375rem;
}

.xtabs-nav::after {
    position: absolute;
    bottom: -3px;
    left: 0;
    content: "";
    width: 0;
    height: 0;
    margin-left: 50%;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    background-image: linear-gradient(264deg, #f7da42, #ffbc34, #f7da42);
    background-size: 200% auto;
}

.xtabs-nav.active::after {
    width: 70%;
    left: 15%;
    margin-left: 0;
    height: 2px;
}

img {
    max-width: 100%;
}

.miner-container {
    padding: 10px;
    max-width: 700px;
    margin: 10px auto 0 auto;
    border-radius: 5px;
    background: #293946;
    position: relative;
}

.miner-image {
    width: 600px;
    cursor: pointer;
}

.miner-image-running {
    cursor: not-allowed;
}

.fade-scale-enter-active,
.fade-scale-group-enter-active,
.fade-scale-group-leave-active,
.fade-scale-leave-active {
    -webkit-transition: all 0.5s cubic-bezier(0.36, 0.66, 0.04, 1);
    transition: all 0.5s cubic-bezier(0.36, 0.66, 0.04, 1);
}

.fade-scale-enter,
.fade-scale-group-enter,
.fade-scale-group-leave-to,
.fade-scale-leave-to {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0;
}

.is-show-prize {
    opacity: 0.05;
}

.show-prize {
    text-align: center;
    position: absolute;
    left: 0;
    top: 6.25rem;
    right: 0;
    z-index: 1;
}

.show-prize .text {
    font-size: 2.25rem;
    color: #fff;
}

.cl-error,
.cl-warning {
    color: #ffe22b;
}

.show-prize .amount {
    margin-left: 0.625rem;
}

.show-prize .currency {
    margin-left: 0.3em;
}

.poster h3 {
    color: #ffffff;
}

.poster ul,
.poster li {
    color: #ffffff;
    list-style: none;
}

.poster li {
    padding: 7px 0;
    line-height: 32px;
}

.poster li span {
    display: inline-block;
    margin-left: 10px;
}

.countdown {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    text-align: center;
    max-width: 400px;
    margin: 60px auto;
    color: #ffffff;
}

.countdown .item {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    margin: 0 3px;
}

.countdown .item h1 {
    font-size: 80px;
    background: linear-gradient(to bottom, #45484d 0%, #000000 100%);
    border-radius: 5px;
    text-shadow: 2px 2px #170101;
}

.table-loading {
    opacity: 0.2;
    transition: all 0.5s;
}

.system-level table {
    background: #293946;
    color: #ffffff;
    width: 100%;
}

.system-level table,
.system-level table th,
.system-level table td {
    border: 1px solid rgb(70, 80, 89);
    border-collapse: collapse;
}

.system-level table th,
.system-level table td {
    padding: 10px 0;
}
</style>