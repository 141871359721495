<template>
    <div class="bg-create">
        <div style=" height: 100vh; align-items: center; flex-direction: column">
            <div style="">
                <img style="position: relative" src="/images/create-account.jpg">
            </div>
            <div style="width:100%;display: flex;margin-top:90px ; margin-bottom:10px; flex-direction: column">
                <div class="gtIpSO" style="margin-left:5px; margin-right:5px">
                    <h3>Join the Anonymous, Provably Fair Bitcoin Casino </h3>
                </div>
                <div class="ifesYM" style="margin-left:5px; margin-right:5px">
                    Instant deposits and withdrawals, no KYC, 1% house edge. Start with a No Deposit Bonus and explore
                    more!
                </div>
                <div class="bt-create">
                    <div class="dDcNHZ" style="margin: auto">
                        <div v-show="!loading" @click="createAccount()">
                            Start Winning!
                        </div>
                        <div v-show="loading" style="padding: 50px;" class="text-center">
                            <div class="spinner-border" role="status">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import Service from "../services/Service";
import store from '../store/store'

export default {
    name: "CreateAccount",
    data() {
        return {
            loading: false
        }
    },
    methods: {
        async setStoreData(data) {
            await store.dispatch("setLogged", true);
            await store.dispatch("setUserID", data.user_id);
            await store.dispatch("setBalance", data.balance);
            await store.dispatch("setEmail", data.email);
            await store.dispatch("setAvatar", data.avatar);
            await store.dispatch("setReadNotification", data.read_notification);
        },
        async createAccount() {
            this.loading = true
            const initData = window.Telegram.WebApp.initData;
            const res = await Service.login({
                init_data: initData
            })
            console.log("res.data: ", res.data)

            if (res.data.success === true) {
                this.$toastr.s('Success!');
                window.location.replace('/kingkong')
            } else {
                this.$toastr.e(res.data.message);
            }
            setTimeout(() => {
                this.loading = true;
            }, 1000);


        }
    }
}
</script>

<style scoped>
.bg-create {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: rgb(44, 58, 68);
    display: flex;
    justify-content: center;
}

.bt-create {
    display: flex;
    align-items: center;
    width: 100%;
    /*margin-top: 100px;*/
    margin-top: 100px;
    left: 0;
}

.gtIpSO {
    font-family: Primary, DefaultFont, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 32px;
    font-weight: 600;
    line-height: 44px;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    color: rgb(236, 250, 255);
}

.ifesYM {
    font-family: Secondary, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    text-align: center;
    color: rgb(207, 217, 224);
}

.dDcNHZ {
    user-select: none;
    cursor: pointer;
    position: relative;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    flex-shrink: 0;
    background: linear-gradient(0deg, rgb(162, 204, 129), rgb(184, 233, 134));
    color: rgb(44, 58, 69);
    width: auto;
    height: 3rem;
    padding: 0px 1.5rem;
    border: 0px;
    border-radius: 0.25rem;
    text-transform: uppercase;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-decoration: none;
    overflow: hidden;
    transition: 312ms cubic-bezier(0.29, 0.04, 0.23, 1);
    font-family: Primary, DefaultFont, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.25rem;
}

@media only screen and (max-width: 1199px) {
    .bg-create {
        width: 100%;
    }

    .bg-create img {
        width: 100%;
    }
}

@media only screen and (max-width: 991px) {
    .bg-create {
        width: 100%;
    }

    .bg-create img {
        width: 100%;
    }
    .bt-create{
        margin-top: 50px;
    }
}

@media only screen and (max-width: 767px) {
    .bg-create {
        width: 540px;
    }

    .bg-create img {
        width: 100%;
    }
    .bt-create{
        margin-top: 50px;
    }
}

@media only screen and (max-width: 575px) {
    .bg-create {
        width: 100%;
    }
    .bt-create{
        margin-top: 30px;
    }
    .gtIpSO {
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 1px;
        text-transform: uppercase;
        text-align: center;
        color: rgb(236, 250, 255);
    }

    .ifesYM {
        font-size: 9px;
        font-weight: 500;
        line-height: 14px;
    }
}

/*@media only screen and (max-width: 375px) {*/
/*    .bg-create {*/
/*        width: 100%;*/
/*    }*/
/*    .bt-create{*/
/*        margin-top: 10px;*/
/*    }*/
/*}*/

</style>