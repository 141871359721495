<template>
    <b-container id="content" style="max-width: 960px;" class="withdaw">
        <div class="text-center" style="margin-top:20px;">
            <b-button variant="secondary" style="margin-left: 10px;" :to="{ name: 'Deposit' }">Deposit</b-button>
            <b-button variant="success" style="margin-left: 10px;" to="#">Withdraw</b-button>
        </div>
        <div v-show="!loading">
            <div class="custom-title mt-30" v-if="step === 0">SELECT COIN TO WITHDRAW</div>
            <div class="custom-title mt-30 withdraw-token" v-if="step > 0">
                <div class="back-button" v-on:click="back">
                    <svg class="arrow-down icon">
                        <use xlink:href="/images/symbol-defs.svg?lang.svg#icon_Arrow"></use>
                    </svg>
                </div>
                <div>
                    WITHDRAW {{ selected_coin_to_withdraw }} ({{ FULL_NAME_COIN[selected_coin_to_withdraw] }})
                </div>
            </div>

            <div class="custom-body" v-if="step === 0">
                <div class="list-coin">
                    <div class="coin-item" v-for="coin in COINS" :key="coin"
                         v-on:click="step = 1; selected_coin_to_withdraw = coin">
                        <div><img :src="`${CDN_URL}/images/coin/${coin}.webp`"/></div>
                        <div style="display: flex;flex-direction: row;gap: 5px;">
                            <div class="flex-middle">{{ coin }}</div>
                            <div class="flex-middle" v-if="coin === 'BNB' || coin === 'USDT'" style="font-size: 12px;">
                                (Binance Smart Chain)
                            </div>
                            <div class="flex-middle" v-if="coin === 'TON'" style="font-size: 12px;">
                                (TON Network)
                            </div>
                        </div>
                        <div style="flex-grow: 1;text-align: right;">
                            {{ (fullBalance[coin] / 1e8).toFixed(NUMBER_OF_DECIMAL_PLACES[coin]) }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="custom-body" v-if="step === 1">
                <div style="max-width: 600px;margin: 0 auto;">
                    <b-form v-on:submit="withdraw"
                            style="display: flex;flex-direction: column; gap: 15px; padding: 15px 0;">
<!--                        <div class="withdraw-form-group"-->
<!--                             v-if="selected_coin_to_withdraw == 'BNB' || selected_coin_to_withdraw == 'USDT'">-->
<!--                            <div class="left-col">Network</div>-->
<!--                            <div class="right-col">-->
<!--                                <div class="option-button active">-->
<!--                                    BEP20-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
                        <div class="withdraw-form-group"
                             v-if="selected_coin_to_withdraw == 'TON'">
                            <div class="left-col">Network</div>
                            <div class="right-col">
                                <div class="option-button active">
                                    TON Network
                                </div>
                            </div>
                        </div>

<!--                        <div class="withdraw-form-group" v-if="selected_coin_to_withdraw == 'BTC'">-->
<!--                            <div class="left-col">Withdrawal Option</div>-->
<!--                            <div class="right-col">-->
<!--                                <div style="display: flex; gap: 10px">-->
<!--                                    <div class="option-button" :class="{ 'active': timeTaken === option.value }"-->
<!--                                         v-for="option in timeTakenOptions" :key="option.value"-->
<!--                                         v-on:click="timeTaken = option.value">-->
<!--                                        {{ option.text }}-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                                <div style="font-size: 12px;margin-top: 5px;">{{-->
<!--                                    timeTakenOptions[timeTaken].desc-->
<!--                                    }}-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->

                        <div class="withdraw-form-group">
                            <div class="left-col">Address</div>
                            <div class="right-col">
                                <b-form-input id="input-address" v-model="form.address" type="text" autocomplete="off"
                                              required
                                              placeholder="Please enter the recipient's address"
                                              style="background: #465059;color: #FFFFFF;border: none;box-shadow: none;"/>
                            </div>
                        </div>
                        <div class="withdraw-form-group">
                            <div class="left-col">Comment</div>
                            <div class="right-col">
                                <b-form-input id="input-address" v-model="form.comment" type="text" autocomplete="off"
                                              required
                                              placeholder="Enter comment"
                                              style="background: #465059;color: #FFFFFF;border: none;box-shadow: none;"/>
                            </div>
                        </div>

                        <div class="withdraw-form-group">
                            <div class="left-col">Amount</div>
                            <div class="right-col">
                                <div style="display: flex;">
                                    <b-form-input id="input-amount" v-model.lazy="form.amount" autocomplete="off"
                                                  @change="amountChange"
                                                  required
                                                  style="border-radius: 0.25rem 0 0 0.25rem;background: #465059;color: #FFFFFF;border: none;box-shadow: none;"/>
                                    <b-button variant="success" style="border-radius: 0 0.25rem 0.25rem 0;"
                                              v-on:click="maxAmount">max
                                    </b-button>
                                </div>
                                <div style="font-size: 12px;margin-top: 5px;">
                                    {{
                                    `Minimum: ${MIN_WITHDRAW[selected_coin_to_withdraw] / 1e8} ${selected_coin_to_withdraw}`
                                    }}
                                </div>
                            </div>
                        </div>


                        <div class="withdraw-form-group">
                            <div class="left-col">Receive amount</div>
                            <div class="right-col">
                                <div style="font-size: 18px;font-weight: bold;">
                                    {{ (Math.max(form.amount - feeComputed, 0)).toFixed(8) }}
                                    {{ selected_coin_to_withdraw }}
                                </div>
                                <div style="font-size: 12px;">
                                    {{ feeComputed }} {{ selected_coin_to_withdraw }} network fee included
                                </div>
                            </div>
                        </div>

                        <div class="withdraw-form-group">
                            <div class="left-col"></div>
                            <div class="right-col">
                                <b-button variant="success" class="button-submit" type="submit" v-if="!buttonLoading">
                                    Withdraw
                                </b-button>
                                <b-button variant="success" class="button-submit" disabled v-else>
                                    <div class="spinner-border spinner-border-sm" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </b-button>
                            </div>
                        </div>
                    </b-form>
                </div>
            </div>

            <div class="custom-body" v-if="step === 2">
                <div class="text-center"
                     style="display: flex; flex-direction: column; gap: 5px;max-width: 400px;margin: 0 auto;">
                    <div>
                        <img :src="`${CDN_URL}/images/success.png`" alt="success" width="60"/>
                    </div>
                    <div style="font-size: 20px;font-weight: bold;">
                        Withdrawal Order Submitted
                    </div>
                    <div class="text-warning">
                        Please wait a few minutes to complete this withdrawal!
                    </div>
                    <div class="recipient-form-group" style="margin-top: 30px;">
                        <div class="flex-middle col-left">
                            Recipient Amount
                        </div>
                        <div class="flex-middle">
                            <img :src="`${CDN_URL}/images/coin/${selected_coin_to_withdraw}.webp`" width="16"
                                 height="16"/>
                        </div>
                        <div class="flex-middle">
                            {{ form.amount }} {{ selected_coin_to_withdraw }}
                        </div>
                    </div>
                    <div class="recipient-form-group">
                        <div class="flex-middle col-left">
                            To Address
                        </div>
                        <div class="flex-middle">
                            <a :href="addressLink(selected_coin_to_withdraw, form.address)" target="_blank">
                                {{ (form.address.substr(0, 6)) }}...{{ (form.address.substr(-6)) }}
                            </a>
                        </div>
                    </div>
                    <div class="recipient-form-group">
                        <div class="flex-middle col-left">
                            Transaction Fee
                        </div>
                        <div class="flex-middle">
                            {{ feeComputed }} {{ selected_coin_to_withdraw }}
                        </div>
                    </div>

                    <div style="margin-top: 10px;">
                        <b-button variant="success" v-on:click="back">View history</b-button>
                    </div>
                </div>
            </div>

            <div v-show="step < 2">
                <div class="custom-title mt-30">WITHDRAWAL HISTORY</div>
                <div id="withdrawal-history">
                    <b-table :items="items" :fields="fields" bordered show-empty :responsive="true" :dark="true"
                             :class="{ 'table-loading': table_loading }" style="font-size: 12px">
                        <template v-slot:empty>
                            <div class="text-center">No more results</div>
                        </template>
                        <template v-slot:cell(createdAt)="data">{{ timeStampToString(data.item.createdAt) }}</template>
                        <template v-slot:cell(amount)="data">
                            <div style="display: flex; gap: 5px; justify-content: center;">
                                <div class="flex-middle"><img :src="`${CDN_URL}/images/coin/${data.item.coin}.webp`"
                                                              width="16"
                                                              height="16"/></div>
                                <div class="flex-middle">
                                    {{ (data.item.amount / 1e8).toFixed(NUMBER_OF_DECIMAL_PLACES[data.item.coin]) }}
                                </div>
                            </div>
                        </template>
                        <template v-slot:cell(fee)="data">
                            {{ (data.item.fee / 1e8).toFixed(NUMBER_OF_DECIMAL_PLACES[data.item.coin]) }}
                        </template>
                        <template v-slot:cell(txid)="data">
                            <a :href="txLink(data.item.coin, data.item.txid)" target="_blank"
                               v-if="data.item.txid !== null">
                                {{ (data.item.txid.substr(0, 6)) }}...{{ (data.item.txid.substr(-6)) }}
                            </a>
                        </template>
                        <template v-slot:cell(address)="data">
                            <a :href="addressLink(data.item.coin, data.item.address)" target="_blank">
                                {{ (data.item.address.substr(0, 6)) }}...{{ (data.item.address.substr(-6)) }}
                            </a>
                        </template>
                        <template v-slot:cell(state)="data">
              <span
                      :class="((data.item.state === 0) ? 'text-warning' : (data.item.state === 1) ? 'text-success' : 'text-danger')">
                {{
                  WITHDRAWAL_STATUS[data.item.state]
                  }}
              </span>
                        </template>

<!--                        <template v-slot:cell(action)="data">-->
<!--                            <a href="#" v-on:click="cancel(data.item.id)" v-if="data.item.state === 0">Cancel</a>-->
<!--                        </template>-->
                    </b-table>
                </div>
                <div class="text-center">
                    <el-pagination layout="prev, pager, next" @current-change="changePage" :page-size="10"
                                   :current-page="currentPage" :total="total"></el-pagination>
                </div>
            </div>
        </div>
        <div v-show="loading" style="padding: 30px;" class="text-center">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </b-container>
</template>
<script>
import Service from "../../services/Service";
import {
    NUMBER_OF_DECIMAL_PLACES,
    MIN_WITHDRAW,
    WITHDRAWAL_FEE,
    BITCOIN_EXPLORER_LINK,
    BSC_EXPLORER_LINK,
    BITCOIN_EXPLORER_ADDRESS_LINK,
    BSC_EXPLORER_ADDRESS_LINK
} from "../../config";
import config, {TON_EXPLORER_ADDRESS_LINK, TON_EXPLORER_LINK} from "@/config.js";

export default {
    name: "Withdrawal",
    data() {
        return {
            buttonLoading: false,
            NUMBER_OF_DECIMAL_PLACES: NUMBER_OF_DECIMAL_PLACES,
            MIN_WITHDRAW: MIN_WITHDRAW,
            WITHDRAWAL_STATUS: [
                'Pending',
                'Success',
                'Canceled'
            ],
            step: 0,
            COINS: ["TON"],
            FULL_NAME_COIN: {
                TON: "TON"
            },
            selected_coin_to_withdraw: 'TON',
            table_loading: true,
            loading: true,
            items: [],
            total: 0,
            currentPage: 1,
            fields: [
                {
                    key: "createdAt",
                    label: "Time",
                    class: "text-center"
                },
                {
                    key: "amount",
                    label: "Amount",
                    class: "text-center"
                },
                {
                    key: "fee",
                    label: "Fee",
                    class: "text-center"
                },
                {
                    key: "address",
                    label: "Address",
                    class: "text-center"
                },
                {
                    key: "txid",
                    label: "TxID",
                    class: "text-center"
                },
                {
                    key: "state",
                    label: "Status",
                    class: "text-center"
                },
                {
                    key: "action",
                    label: "Act",
                    class: "text-center"
                }
            ],
            form: {
                address: "",
                amount: 0,
                comment:""
            },
            timeTaken: 1,
            timeTakenOptions: [
                {value: 0, text: "Weekly", desc: "Frequency: Every Sunday"},
                {value: 1, text: "Slow", desc: "Time taken: 6-24 Hours"},
                {value: 2, text: "Instant", desc: "Time taken: ~15 Minutes"}
            ]
        };
    },
    async created() {
        this.loading = true;
        await this.getWithdrawals();
        this.loading = false;
    },
    methods: {
        async cancel(withdrawal_id) {
            const res = await Service.cancelWithdrawal({
                id: withdrawal_id
            }).catch(() => {
                this.$swal(
                    "Cancel Withdrawal",
                    "Fail to cancel this withdrawal",
                    "error"
                );
            });
            if (res !== undefined) {
                this.$swal(
                    "Cancel Withdrawal",
                    res.data.message,
                    res.data.success ? "success" : "error"
                );
                if (res.data.success) {
                    await this.$store.dispatch("setBalance", res.data.balance);
                    await this.getWithdrawals();
                }
            }
        },
        changePage(page) {
            this.currentPage = page;
            this.getWithdrawals();
        },
        async getWithdrawals() {
            this.table_loading = true;
            const res = await Service.getWithdrawals({
                page: this.currentPage
            }).catch(() => {
            });
            this.table_loading = false;
            if (res !== undefined && res.data.success === true) {
                this.items = res.data.list;
                this.total = res.data.total;
            }
        },
        async withdraw(evt) {
            evt.preventDefault();
            this.buttonLoading = true;
            const res = await Service.withdrawal({
                address: this.form.address.trim(),
                coin: this.selected_coin_to_withdraw,
                amount: Math.round(parseFloat(this.form.amount) * 1e8),
                comment: this.form.comment,
                type: this.timeTaken
            }).catch(() => {
            });
            if (res !== undefined) {
                if (res.data.success === true) {
                    await this.$store.dispatch("setBalance", res.data.balance);
                    await this.getWithdrawals();
                    this.step = 2;
                    // this.$swal("Withdraw", res.data.message, "success").then(async () => {
                    //   this.form.address = "";
                    //   this.form.amount = "";
                    //   await this.getWithdrawals();
                    // });
                } else {
                    this.$swal("Withdraw", res.data.message, "error");
                }
            }
            this.buttonLoading = false;
        },
        timeStampToString(unix_timestamp) {
            const date = new Date(unix_timestamp);
            return (
                ("0" + date.getDate()).slice(-2) +
                "/" +
                ("0" + (date.getMonth() + 1)).slice(-2) +
                "/" +
                date.getFullYear() +
                " " +
                ("0" + date.getHours()).slice(-2) +
                ":" +
                ("0" + date.getMinutes()).slice(-2)
            );
        },
        async maxAmount(evt) {
            evt.preventDefault();
            this.form.amount = (this.fullBalance[this.selected_coin_to_withdraw] / 1e8).toFixed(8);
        },
        amountChange() {
            this.form.amount = parseFloat(this.form.amount)
                .toFixed(8)
                .toString();
        },
        back(evt) {
            evt.preventDefault();
            this.step = 0;
            this.form.amount = 0;
            this.form.address = "";
        },
        txLink(coin, txid) {
            if (coin === "BTC")
                return `${BITCOIN_EXPLORER_LINK}${txid}`;
            if (coin === "BNB" || coin === "USDT")
                return `${BSC_EXPLORER_LINK}${txid}`
            if (coin === "TON")
                return `${TON_EXPLORER_LINK}${txid}`
        },
        addressLink(coin, txid) {
            if (coin === "BTC")
                return `${BITCOIN_EXPLORER_ADDRESS_LINK}${txid}`;
            if (coin === "BNB" || coin === "USDT")
                return `${BSC_EXPLORER_ADDRESS_LINK}${txid}`
            if (coin === "TON")
                return `${TON_EXPLORER_ADDRESS_LINK}${txid}`
        }
    },
    computed: {
        fullBalance() {
            return this.$store.getters.balance;
        },
        feeComputed() {
            return WITHDRAWAL_FEE[this.selected_coin_to_withdraw] / 1e8
        },
        CDN_URL() {
            return config.CDN_URL;
        },
    }
};
</script>
<style>
#withdrawal-history table.b-table {
    background: transparent;
}

#withdrawal-history .table-bordered th,
#withdrawal-history .table-bordered td {
    border: none;
    border-bottom: 1px solid #2f3f4b;
}

.table-loading {
    opacity: 0.2;
    transition: all 0.5s;
}

.withdaw .mt-30 {
    margin-top: 30px;
}

.withdaw svg.arrow-down {
    fill: #abb6c2;
    width: 20px;
    height: 20px;
    -webkit-transform: rotate(90deg);
    transform: rotate(180deg);
}

.withdaw .withdraw-token {
    display: flex;
    position: relative;
    justify-content: center;
}

.withdaw .back-button {
    cursor: pointer;
    position: absolute;
    left: 10px;
}

.withdaw .list-coin {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 500px;
    margin: 0 auto;
}

.withdaw .list-coin .coin-item {
    padding: 10px;
    display: flex;
    cursor: pointer;
    gap: 10px;
    /* border: 1px solid #ccc; */
    border-radius: 5px;
    background: #465059;
}

.withdaw .list-coin .coin-item:hover {
    background: #6c757d;
}

.withdaw .list-coin .coin-item > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.withdaw .list-coin .coin-item img {
    width: 32px;
    height: 32px;
}

.flex-middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.withdraw-form-group {
    display: flex;
    gap: 10px;
}

.withdraw-form-group .left-col {
    width: 180px;
    /* display: flex;
    flex-direction: column;
    justify-content: center; */
    font-size: 14px;
    font-weight: bold;
}

.withdraw-form-group .right-col {
    flex-grow: 1;
}

.withdraw-form-group .option-button {
    background: #465059;
    display: inline-block;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    min-width: 80px;
    text-align: center;
}

.withdraw-form-group .option-button.active {
    border: 1px solid #28a745;
}

.withdraw-form-group .button-submit {
    width: 300px;
    max-width: 100%;
}

.recipient-form-group {
    display: flex;
    justify-content: center;
    gap: 5px;
    font-size: 14px;
}

.recipient-form-group .col-left {
    text-align: left;
    flex-grow: 1;
}

.el-pagination {
    background: #293946;
    border-radius: 3px;
}

.el-pagination li {
    background-color: transparent !important;
}

.el-pagination button {
    background-color: transparent !important;
}

.el-pagination button:disabled {
    background-color: transparent !important;
}

.el-pager li,
.el-pagination .btn-next,
.el-pagination .btn-prev {
    color: #8a99a5 !important;
}

.el-pager li.active,
.el-pager li:hover,
.el-pagination button:hover {
    color: #f6a623 !important;
}

.el-pagination button:disabled:hover {
    color: #8a99a5 !important;
}

@media screen and (max-width: 600px) {
    .withdraw-form-group {
        flex-direction: column;
        gap: 3px;
    }

    .withdraw-form-group .left-col {
        font-weight: bold;
        font-size: 12px;
        gap: 3px;
    }

    .withdraw-form-group .button-submit {
        width: 100%;
    }
}
</style>

