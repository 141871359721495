<template>
  <b-container id="content">
    <div style="margin: 0 auto;background: #22303b;padding: 20px;border-radius: 5px;">
      <h1>Frequently Asked Questions</h1>
      <div id="content_text">
        <p class="faq_question bold">When will I get paid if I have Auto Withdraw enabled?</p>
        <div class="faq_answer">
          <p>
            If you have Auto-Withdraw enabled in your account, your account balance will
            go
            into PENDING on Sunday (if it is more than the min. withdraw amount) and you will be able to see
            this
            under PENDING PAYOUT on the FREE BTC page. The Bitcoins will be sent to your Bitcoin wallet soon
            after.
            You will receive an email notifying you of the payment if you have an email address associated with
            your
            account. If you wish to know the exact time when your balance will go into pending, click on the
            button
            that says
            <b>WITHDRAW</b> in the
            <b>FREE BTC</b> page and then click on
            <b>AUTO</b> and you will see
            a
            timer counting down to the payout time.
          </p>
        </div>
        <p class="faq_question bold">How do I enable Auto Withdraw?</p>
        <div class="faq_answer">
          <p>
            By clicking on the button that says
            <b>WITHDRAW</b> in the
            <b>FREE BTC</b>
            page,
            then clicking on
            <b>AUTO</b> and checking the box next to
            <b>AUTO WITHDRAW</b>. If you enable
            auto-withdraw after the countdown timer has run out for the current payout cycle, you will receive
            your
            payment the next week.
          </p>
        </div>
        <p class="faq_question bold">When will I get paid if I have requested a Manual Withdraw?</p>
        <div class="faq_answer">
          <p>
            If you request a manual withdrawal, the Bitcoins will be sent to your wallet
            within 6 hours of you initiating the request.
          </p>
        </div>
        <p class="faq_question bold">When will I get paid if I have requested an Instant Withdraw?</p>
        <div class="faq_answer">
          <p>
            If you request an instant withdrawal, the Bitcoins will be sent to your
            wallet
            within 15 minutes of you initiating the request.
          </p>
        </div>
        <p class="faq_question bold">How can I change my Bitcoin address or Email address?</p>
        <div class="faq_answer">
          <p>
            By clicking on the button that says
            <b>PROFILE</b> in the top menu. You will
            be
            able to change your email address only if the email that is currently attached to your account is
            invalid or it is bouncing our emails back.
          </p>
        </div>
        <p class="faq_question bold">Where can I see my referral link or my referrals?</p>
        <div class="faq_answer">
          <p>
            By clicking on the button that says
            <b>REFER</b> in the top menu.
          </p>
        </div>
        <p class="faq_question bold">How do I refer my friends?</p>
        <div class="faq_answer">
          <p>
            Share your referral link with your friends and ask them to visit it and
            create an
            account. On doing so, they will be automatically added as your referral and you will get 50% of
            their
            free btc winnings as commission! Nothing will be deducted from their account, we pay the 50% out of
            our
            pocket. You will also receive 1 free ticket to our weekly lottery every time your friend plays a
            free
            roll! If you do not know how to get your referral link, please see the question above.
          </p>
        </div>
        <p class="faq_question bold">I have lost/wish to reset my password?</p>
        <div class="faq_answer">
          <p>
            Please go to the login page and click on the link that says
            <b>
              Forgot
              Password
            </b> in the login box.
          </p>
        </div>
        <p
          class="faq_question bold"
        >Why does the amount of Bitcoins that you can win, keep changing?</p>
        <div class="faq_answer">
          <p>
            The amount of bitcoins that you can win with
            <b>FREE BTC</b> depends on the
            current bitcoin price and the biggest prize is fixed at US$200 and the other prizes in proportion to
            it.
            So, when the price of a bitcoin goes down, the reward amount calculated in bitcoins goes up and the
            other way round is also true. So, regardless of the current bitcoin price, you have a fair chance of
            winning US$200 in bitcoins on each roll.
          </p>
        </div>
        <p class="faq_question bold">Can you reverse a payment that has already been sent?</p>
        <div class="faq_answer">
          <p>
            Unfortunately bitcoin payments are irreversible and so once a payment has
            been
            sent, we have no way of getting it back. You should ensure that the correct withdrawal address is
            specified in the
            <b>PROFILE</b> page before requesting a payment or enabling auto-withdraw.
          </p>
        </div>
        <p class="faq_question bold">Where can I check my activity on this website?</p>
        <div class="faq_answer">
          <p>
            By clicking on
            <b>STATS</b> in the above menu and then on
            <b>
              PERSONAL
              STATS
            </b>.
          </p>
        </div>
        <p class="faq_question bold">How can I keep my account secure?</p>
        <div class="faq_answer">
          <p>
            By using a strong password, not re-using the same password on any other
            website
            and not sharing your password with anybody else. We recommend using a password manager like
            <a href="https://lastpass.com/" target="_blank">LastPass</a> to create and store your
            passwords. If
            you do not use these security practices and your account gets hacked, we shall not be able to help
            you.
          </p>
        </div>
        <p class="faq_question bold">How can I contact you?</p>
        <div class="faq_answer">
          <p>
            By filling in the form below. Please read the questions above before sending
            us
            an email. We receive hundreds of emails everyday and do not have the resources to reply to all of
            them,
            so we have a policy of not responding to emails asking questions that have already been answered on
            this
            page.
          </p>
        </div>
      </div>
    </div>
  </b-container>
</template>
<script>
export default {
  name: "FAQ"
};
</script>
<style scoped>
.faq_question {
  font-weight: 400;
  font-size: 1.0625rem;
  color: rgb(255, 255, 255);
  margin: 10px 0;
}

h1 {
  font-size: 22px;
}

#content_text {
  color: #6e8899;
  font-size: 14px;
}

#content_text h2 {
  font-size: 18px;
}

#content_text h3 {
  font-weight: 400;
  font-size: 1.0625rem;
  color: rgb(255, 255, 255);
}
</style>