<template>
    <b-container id="content" style="max-width: 960px;" class="deposit">
        <div class="text-center" style="margin-top:20px;">
            <b-button variant="success" style="margin-left: 10px;" to="#">Deposit</b-button>
            <b-button variant="secondary" style="margin-left: 10px;" :to="{ name: 'Withdraw' }">Withdraw</b-button>
        </div>
        <div>
            <div style="margin-top: 30px;" class="custom-title deposit-token" v-if="step === 0">
                SELECT COIN TO DEPOSIT
            </div>
            <div style="margin-top: 30px;" class="custom-title deposit-token" v-if="step === 1">

                <div class="back-button" v-on:click="back">
                    <svg class="arrow-down icon">
                        <use xlink:href="/images/symbol-defs.svg?lang.svg#icon_Arrow"></use>
                    </svg>
                </div>
                <div>DEPOSIT {{ selected_coin_to_deposit }} ({{ FULL_NAME_COIN[selected_coin_to_deposit] }})</div>
            </div>

            <div class="custom-body" v-if="step === 0">
                <div class="list-coin">
                    <div class="coin-item" v-for="coin in COINS" :key="coin" v-on:click="deposit(coin)">
                        <div><img :src="`${CDN_URL}/images/coin/${coin}.webp`"/></div>
                        <div style="display: flex;flex-direction: row;gap: 5px;">
                            <div class="flex-middle">{{ coin }}</div>
                            <div class="flex-middle" v-if="coin === 'BNB' || coin === 'USDT'" style="font-size: 12px;">
                                (Binance Smart
                                Chain)
                            </div>
                            <div class="flex-middle" v-if="coin === 'TON'" style="font-size: 12px;">
                                (TON Network)
                            </div>
                        </div>
                        <div style="flex-grow: 1;text-align: right;">
                            {{ (fullBalance[coin] / 1e8).toFixed(NUMBER_OF_DECIMAL_PLACES[coin]) }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="custom-body" v-if="step === 1" style="position: relative">
                <div :class="{'enable_blur': enable_blur}" style="max-width: 600px;margin: 0 auto;">
                    <div  v-show="!loading">
                        <div style="margin: 10px 0;" class="text-center">
                            <div
                                style="padding: 10px; background: #FFFFFF;width: 220px; height: 220px;margin: 0 auto;border-radius: 5px;position: relative;">
                                <qrcode-vue :value="address" size="200" level="H"></qrcode-vue>
                                <div style="position: absolute;top: 92px;left: 92px;">
                                    <img :src="`${CDN_URL}/images/coin/${selected_coin_to_deposit}.webp`" width="36"
                                         height="36"/>
                                </div>
                            </div>
                        </div>

                        <div style="max-width: 400px;margin: 10px auto;display: flex;flex-direction: column">
                            <div>
                                Deposit Address:
                            </div>
                            <div style=" position: relative;display: flex">
                            <b-form-input v-model="address" readonly="readonly" id="deposit-address"
                                          style="border-radius: 0.25rem 0 0 0.25rem;background: #465059;color: #FFFFFF;border: none;"/>
                                    <button type="button" class="btn btn-success" style="border-radius: 0 0.25rem 0.25rem 0;"
                                            v-on:click="copyToClipboard('deposit-address')">copy
                                    </button>
                            </div>

                        </div>


                        <div style="max-width: 400px;margin: 10px auto;display: flex; flex-direction: column">
                            <div>
                                Code:
                            </div>
                            <div style="display: flex">
                                <b-form-input v-model="deposit_code" readonly="readonly" id="deposit-code"
                                              style="border-radius: 0.25rem 0 0 0.25rem;background: #465059;color: #FFFFFF;border: none;"/>
                                <button type="button" class="btn btn-success" style="border-radius: 0 0.25rem 0.25rem 0;"
                                        v-on:click="copyToClipboard('deposit-code')">copy
                                </button>
                            </div>

                        </div>
                        <div class="text-center text-warning" style="font-size: 14px;"
                             v-if="selected_coin_to_deposit === 'TON'">
                            Reminder: Only send TON with Deposit Address and Code
                        </div>
                        <p style="display: inline-block; margin-top:5px; text-align: center">
                            Please double-check that the <b>Deposit address</b> and <b>Code </b>you have
                            copied is correct before sending <b>{{ selected_coin_to_deposit }}</b> from your wallet
                            since there are some malware that can change the deposit address after you copy it, resulting in coins being sent to some other
                            address and not your deposit address.
                        </p>
                    </div>
                    <div v-show="loading" style="padding: 30px;" class="text-center">
                        <div class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
                <div v-if="enable_blur" class="style_confirmMenu__3CCvk" style="max-width: 600px;margin: 0 auto;">
                    <div  style="z-index: 1" v-show="!loading">

                        <div style="max-width: 400px;margin: 100px auto;display: flex;flex-direction: column; align-items: center; border-radius:10px; ;background-color: #465059">
                            <div style="padding:10px;">
                                <p style="padding-left:10px; font-size:24px">
                                    <b>Code is required</b>
                                </p>
                                <p class="text-center text-warning" style="padding:10px ;font-size: 14px;">
                                   TON deposit require the code to be filled on your withdrawal platform. Otherwise, you'll lose your assets
                                </p>
                                <div style="justify-content: center;display:flex;">
                                    <button type="button" class="btn btn-success" style=" width:30%;border-radius: 4px;"
                                            v-on:click="confirmDeposit()"> Confirm
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-show="loading" style="padding: 30px;" class="text-center">
                        <div class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- history -->
        <div v-if="step === 0">
            <div style="margin-top: 30px;" class="custom-title">DEPOSIT HISTORY</div>
            <div id="deposit-history">
                <b-table :items="items" :fields="fields" bordered show-empty :dark="true" style="font-size: 12px;"
                         :class="{ 'table-loading': table_loading }" :responsive="true">
                    <template v-slot:empty>
                        <div class="text-center">No more results</div>
                    </template>
                    <template v-slot:cell(createdAt)="data">{{ timeStampToString(data.item.createdAt) }}</template>
                    <template v-slot:cell(txid)="data">
                        <a :href="`${txLink(data.item.coin, data.item.txid)}`" target="_blank">
                            {{
                            (data.item.txid.substr(0,
                                6))
                            }}...{{ (data.item.txid.substr(-6)) }}
                        </a>
                    </template>
                    <template v-slot:cell(amount)="data">
                        <div style="display: flex; gap: 5px; justify-content: center;">
                            <img :src="`${CDN_URL}/images/coin/${data.item.coin}.webp`" width="16" height="16"/>
                            {{ (data.item.amount / 1e8).toFixed(8) }}
                        </div>
                    </template>

                    <template v-slot:cell(state)="data">
                        <div :class="{ 'text-success': data.item.state === 'Success' }">
                            {{ data.item.state }}
                        </div>
                    </template>
                </b-table>
            </div>
            <div class="text-center">
                <el-pagination layout="prev, pager, next" @current-change="changePage" :page-size="10"
                               :current-page="currentPage"
                               :total="total"></el-pagination>
            </div>
        </div>
    </b-container>
</template>
<script>
import Service from "../../services/Service";
import QrcodeVue from "qrcode.vue";
import {NUMBER_OF_DECIMAL_PLACES, REQUIRES_CONFIRMATION, BITCOIN_EXPLORER_LINK, BSC_EXPLORER_LINK} from "../../config";
import config, {TON_EXPLORER_LINK} from "@/config.js";

export default {
    name: "Deposit",
    data() {
        return {
            NUMBER_OF_DECIMAL_PLACES: NUMBER_OF_DECIMAL_PLACES,
            REQUIRES_CONFIRMATION: REQUIRES_CONFIRMATION,
            step: 0,
            selected_coin_to_deposit: "TON",
            COINS: ["TON"],
            FULL_NAME_COIN: {
                TON: "Ton Network",
            },
            table_loading: true,
            loading: true,
            items: [],
            total: 0,
            currentPage: 1,
            fields: [
                {
                    key: "createdAt",
                    label: "Time",
                    class: "text-center"
                },
                {
                    key: "txid",
                    label: "TxID",
                    class: "text-center"
                },
                {
                    key: "amount",
                    label: "Amount",
                    class: "text-center"
                },
                {
                    key: "state",
                    label: "Status",
                    class: "text-center"
                }
            ],
            address: "",
            deposit_code: "",
            enable_blur: true,
        };
    },
    methods: {
        changePage(page) {
            this.currentPage = page;
            this.getDeposits();
        },
        async getDeposits() {
            this.table_loading = true;
            const res = await Service.getDeposits({
                page: this.currentPage
            }).catch(() => {
            });
            this.table_loading = false;
            if (res !== undefined && res.data.success === true) {
                this.items = res.data.list;
                this.total = res.data.total;
            }
        },
        async confirmDeposit(){
            this.enable_blur = false
        },
        timeStampToString: function (unix_timestamp) {
            const date = new Date(unix_timestamp);
            return (
                ("0" + date.getDate()).slice(-2) +
                "/" +
                ("0" + (date.getMonth() + 1)).slice(-2) +
                "/" +
                date.getFullYear() +
                " " +
                ("0" + date.getHours()).slice(-2) +
                ":" +
                ("0" + date.getMinutes()).slice(-2)
            );
        },
        copyToClipboard: function (selector) {
            const id = `#${selector}`
            const copyAddress = document.querySelector(id);
            copyAddress.setAttribute("type", "text");
            copyAddress.select();
            try {
                document.execCommand("copy");
            } catch (err) {
                this.$swal("Copy to clipboard", "Oops, unable to copy", "error");
            }
            this.$toastr.s('Copied!');
            window.getSelection().removeAllRanges();
        },
        async getWalletAddress() {
            this.loading = true;
            this.address = "";
            const res = await Service.getWalletAddress({
                coin: this.selected_coin_to_deposit
            }).catch(() => {
            });
            if (res !== undefined && res.data.success) {
                this.address = res.data.data.address;
                this.deposit_code = res.data.data.deposit_code;
                this.loading = false;
            }
        },
        back(evt) {
            evt.preventDefault();
            this.step = 0;
        },
        async deposit(selected_coin_to_deposit) {
            this.step = 1;
            this.selected_coin_to_deposit = selected_coin_to_deposit;
            await this.getWalletAddress();
        },
        txLink(coin, txid) {
            if (coin === "BTC")
                return `${BITCOIN_EXPLORER_LINK}${txid}`;
            if (coin === "BNB" || coin === "USDT")
                return `${BSC_EXPLORER_LINK}${txid}`
            if(coin === "TON"){
                return `${TON_EXPLORER_LINK}${txid}`
            }
        },

    },
    async created() {
        await this.getDeposits();
    },
    components: {
        QrcodeVue
    },
    computed: {
        fullBalance() {
            return this.$store.getters.balance;
        },
        CDN_URL() {
            return config.CDN_URL;
        },
    }
};
</script>
<style>
#deposit-history table.b-table {
    background: transparent;
}

#deposit-history .table-bordered th,
#deposit-history .table-bordered td {
    border: none;
    border-bottom: 1px solid #2f3f4b;
}

#deposit-address:focus {
    box-shadow: none !important;
}

.table-loading {
    opacity: 0.2;
    transition: all 0.5s;
}

.deposit svg.arrow-down {
    fill: #abb6c2;
    width: 20px;
    height: 20px;
    -webkit-transform: rotate(90deg);
    transform: rotate(180deg);
}

.deposit .deposit-token {
    display: flex;
    position: relative;
    justify-content: center;
}

.deposit .back-button {
    cursor: pointer;
    position: absolute;
    left: 10px;
}

.deposit .list-coin {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 500px;
    margin: 0 auto;
}

.deposit .list-coin .coin-item {
    padding: 10px;
    display: flex;
    cursor: pointer;
    gap: 10px;
    /* border: 1px solid #ccc; */
    border-radius: 5px;
    background: #465059;
}

.deposit .list-coin .coin-item:hover {
    background: #6c757d;
}

.deposit .list-coin .coin-item > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.deposit .list-coin .coin-item img {
    width: 32px;
    height: 32px;
}

.flex-middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.enable_blur{
    filter:blur(4px);
}
.style_confirmMenu__3CCvk {
    position: absolute;
    top: 10px;
    left: 0;
    right:0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
}
</style>