import Vue from 'vue'
import Router from 'vue-router'
import Layout from "../components/Layout"
import Page404 from "../components/Page404"

import Faucet from "../views/Home"
// import Mining from "../views/Mining";
// import Rankings from "../views/Rankings";
//
import Deposit from "../views/User/Deposit";
import Withdraw from "../views/User/Withdraw";
import Profile from "../views/User/Profile";
// import History from "../views/User/History";
// import Settings from "../views/User/Settings";
import Affiliate from "../views/User/Affiliate";

import ProvablyFair from "../views/Pages/ProvablyFair";
import PrivacyPolicy from "../views/Pages/PrivacyPolicy";
import Contact from "../views/Pages/Contact";
import TermsOfUse from "../views/Pages/TermsOfUse";
import FAQ from "../views/Pages/FAQ";
import Surveys from "../views/User/Surveys.vue"

// import MultiplyBTC from "../views/Games/Multiply"
// import Crash from "../views/Games/Crash";
import Kong from "../views/Games/Kong";
// import Keno from "../views/Games/Keno";
// import VideoPoker from "../views/Games/VideoPoker"

import Service from "../services/Service";
import store from '../store/store'
import CreateAccount from "@/views/CreateAccount.vue";
Vue.use(Router);

async function setStoreData  (data) {
    await store.dispatch("setLogged", true);
    await store.dispatch("setUserID", data.user_id);
    await store.dispatch("setTelegramID", data.telegram_id);
    await store.dispatch("setBalance", data.balance);
    await store.dispatch("setEmail", data.email);
    await store.dispatch("setAvatar", data.avatar);
    await store.dispatch("setReadNotification", data.read_notification);
}
const checkAuthenticated = async () => {
    const res = await Service.getUser().catch(() => { });
    if (res !== undefined && res.data.isLogged === true) {
        await setStoreData(res.data)
    }
}
const ifAuthenticated = async (to, from, next) => {
    if (store.getters.isLogged === null) {
        await checkAuthenticated();
    }
    if (store.getters.isLogged) {
        next()
        return
    }
    window.location.replace("/login");
}
// const login = async () => {
//     await Service.quickLogin().catch(() => { });
// }
const router = new Router({
    mode: 'history',
    linkExactActiveClass: 'is-active',
    routes: [
        {
            path: '/login',
            component: CreateAccount,
        },
        {
            path: '/',
            component: Layout,
            children: [
                {
                    path: '/',
                    name: 'Faucet',
                    component: Faucet,
                    beforeEnter: ifAuthenticated,
                },
                {
                    path: 'affiliate',
                    name: 'Affiliate',
                    component: Affiliate,
                    beforeEnter: ifAuthenticated,
                },
                // {
                //     path: 'history',
                //     name: 'History',
                //     component: History,
                //     beforeEnter: ifAuthenticated,
                // },
                {
                    path: 'deposit',
                    name: 'Deposit',
                    component: Deposit,
                    beforeEnter: ifAuthenticated,
                },
                {
                    path: 'withdraw',
                    name: 'Withdraw',
                    component: Withdraw,
                    beforeEnter: ifAuthenticated,
                },

                {
                    path: 'kingkong',
                    name: 'Kong',
                    component: Kong,
                    beforeEnter: ifAuthenticated,
                },

                {
                    path: 'survey',
                    name: 'Surveys',
                    component: Surveys,
                },
                {
                    path: 'contact',
                    name: 'Contact',
                    component: Contact,
                },
                {
                    path: 'profile',
                    name: 'Profile',
                    component: Profile,
                    beforeEnter: ifAuthenticated,
                },
                { path: "*", component: Page404 },
                // {
                //     path: 'multiply-btc',
                //     name: 'MultiplyBTC',
                //     component: MultiplyBTC,
                //     beforeEnter: ifAuthenticated,
                // },
                // {
                //     path: 'crash',
                //     name: 'Crash',
                //     component: Crash,
                //     beforeEnter: ifAuthenticated,
                // },
                // {
                //     path: 'keno',
                //     name: 'Keno',
                //     component: Keno,
                //     beforeEnter: ifAuthenticated,
                // },
                // {
                //     path: 'video-poker',
                //     name: 'VideoPoker',
                //     component: VideoPoker,
                //     beforeEnter: ifAuthenticated,
                // },
                {
                    path: 'terms-of-use',
                    name: 'TermsOfUse',
                    component: TermsOfUse,
                },
                {
                    path: 'privacy-policy',
                    name: 'PrivacyPolicy',
                    component: PrivacyPolicy,
                },
                {
                    path: 'provably-fair',
                    name: 'ProvablyFair',
                    component: ProvablyFair,
                },
                {
                    path: 'faq',
                    name: 'FAQ',
                    component: FAQ,
                },

                // {
                //     path: 'settings',
                //     name: 'Settings',
                //     component: Settings,
                //     beforeEnter: ifAuthenticated,
                // },
                // {
                //     path: 'mining',
                //     name: 'Mining',
                //     component: Mining,
                //     beforeEnter: ifAuthenticated,
                // },
                // {
                //     path: 'rankings',
                //     name: 'Rankings',
                //     component: Rankings,
                //     beforeEnter: ifAuthenticated,
                // },
            ]
        },
    ],
});

export default router