import Api from './Api'

export default {
    quickLogin() {
        return Api().get('user/quick_login');
    },
    login(params) {
        return Api().post('user/login', params)
    },
    signUp(params) {
        return Api().post('user/sign_up', params)
    },
    getUser() {
        return Api().get('user/info');
    },
    logout() {
        return Api().get('user/logout');
    },
    getSecurity() {
        return Api().get('user/security');
    },
    enableTwoFa() {
        return Api().post('user/enable_2fa');
    },
    completeTwoFa(params) {
        return Api().post('user/complete_2fa', params);
    },
    disableTwoFa(params) {
        return Api().post('user/disable_2fa', params);
    },
    loginOTP(params) {
        return Api().post('user/login_otp', params);
    },
    forgetPassword(params) {
        return Api().post('user/forget_password', params);
    },
    changePassword(params) {
        return Api().post('user/change_password', params);
    },
    updateProfile(params) {
        return Api().post('user/update_profile', params);
    },
    getProfile() {
        return Api().get('user/profile');
    },
    getWalletAddress(params) {
        return Api().post('user/get_wallet_address', params);
    },
    getDeposits(params) {
        return Api().post('deposit/get_deposits', params);
    },
    getWithdrawals(params) {
        return Api().post('withdraw/get_withdrawals', params);
    },
    withdrawal(params) {
        return Api().post('withdraw/submit', params);
    },
    cancelWithdrawal(params) {
        return Api().post('withdraw/cancel', params);
    },
    diceBet(params) {
        return Api().post('dice/bet', params);
    },
    diceInfo() {
        return Api().get('dice/info');
    },

    miningInfo() {
        return Api().post('free_btc/info');
    },
    claimFreeBTC() {
        return Api().post('free_btc/mining');
    },
    freeBTCCaptcha(params) {
        return Api().post('free_btc/captcha', params);
    },
    myDiceBetHistory(params) {
        return Api().post('history/my_dice_bet', params);
    },
    allDiceBetHistory(params) {
        return Api().post('history/all_dice_bet', params);
    },
    allCrashRound(params) {
        return Api().post('history/crash_round', params);
    },
    myCrashBetHistory(params) {
        return Api().post('history/my_crash_bet', params);
    },
    allCrashBet(params) {
        return Api().post('history/all_crash_bet', params);
    },
    miningHistory(params) {
        return Api().post('history/mining', params);
    },
    allMiningHistory(params) {
        return Api().post('history/all_mining', params);
    },
    transactionsHistory(params) {
        return Api().post('history/transactions', params);
    },
    getReferral(params) {
        return Api().post('referral/list', params);
    },
    getReferralEarned() {
        return Api().post('referral/earned');
    },
    sendContact(params) {
        return Api().post('contact/send', params);
    },
    getNotifications() {
        return Api().post('notification/list');
    },
    kongInfo() {
        return Api().post('kong/index');
    },
    kongCreateBet(params) {
        return Api().post('kong/create_bet', params);
    },
    kongUpdateBet(params) {
        return Api().post('kong/update_bet', params);
    },
    kongEndBet() {
        return Api().post('kong/end_bet');
    },
    myKongBetHistory(params) {
        return Api().post('history/my_kong_bet', params);
    },
    allKongBetHistory(params) {
        return Api().post('history/all_kong_bet', params);
    },
    notificationSubscribe(params) {
        return Api().post('web_push/subscribe', params);
    },
    buyPlans(params) {
        return Api().post('mining/buy', params);
    },
    getInvestments(params) {
        return Api().post('mining/investments', params);
    },
    getInterestPaid(params) {
        return Api().post('mining/interest_paid', params);
    },
    faucetClaim(){
        return Api().post('faucet/claim',)
    },
    faucetInfo() {
        return Api().post('faucet/info')
    },
    getSurveyHistory(params) {
        return Api().post('survey/get_list',params)
    },
    kenoBet(params) {
        return Api().post('keno/bet', params);
    },
    kenoInfo() {
        return Api().get('keno/info');
    },
    myKenoBetHistory(params) {
        return Api().post('history/my_keno_bet', params);
    },
    allKenoBetHistory(params) {
        return Api().post('history/all_keno_bet', params);
    },

    videoPokerInfo() {
        return Api().get('video_poker/info');
    },
    videoPokerBet(params) {
        return Api().post('video_poker/bet', params);
    },
    videoPokerDeal(params) {
        return Api().post('video_poker/deal', params);
    },
    myVideoPokerBetHistory(params) {
        return Api().post('history/my_video_poker_bet', params);
    },
    allVideoPokerBetHistory(params) {
        return Api().post('history/all_video_poker_bet', params);
    }
}